import React from 'react';
import { Grid2, Typography } from '@mui/material';

const TermsAndConditions = () => {
  return (
    <Grid2 container display={'flex'} sx={{ width: '100%', padding: '20px' }}>
      <Grid2 container display={'flex'} alignItems={'baseline'}>
        <Typography variant="h4">Terms and Conditions</Typography>
      </Grid2>
      <Grid2
        container
        sx={{
          display: 'flex',
          width: '95%',
          height: '78vh',
          overflow: 'auto',
        }}
      >
        <Typography variant="body1" height={'100%'}>
          <p>
            Welcome to our application. By using our services, you agree to the
            following terms and conditions:
          </p>

          <ol>
            <li>
              <strong>Acceptance of Terms</strong>
              <ul>
                <li>
                  By accessing and using our services, you accept and agree to
                  be bound by the terms and provision of this agreement.
                </li>
                <li>
                  If you do not agree to abide by the above, please do not use
                  this service.
                </li>
              </ul>
            </li>
            <li>
              <strong>Modification of Terms</strong>
              <ul>
                <li>
                  We reserve the right to modify these terms at any time. We
                  will notify you of any changes by posting the new terms on our
                  website.
                </li>
                <li>
                  It is your responsibility to review these terms periodically.
                </li>
                <li>
                  Continued use of the service after any such changes shall
                  constitute your consent to such changes.
                </li>
              </ul>
            </li>
            <li>
              <strong>User Responsibilities</strong>
              <ul>
                <li>
                  You are responsible for maintaining the confidentiality of
                  your account and password.
                </li>
                <li>
                  You agree to accept responsibility for all activities that
                  occur under your account or password.
                </li>
                <li>
                  You must not misuse our services by knowingly introducing
                  viruses, trojans, worms, logic bombs, or other material that
                  is malicious or technologically harmful.
                </li>
                <li>
                  You must not attempt to gain unauthorized access to our
                  services, the server on which our services are stored, or any
                  server, computer, or database connected to our services.
                </li>
                <li>
                  You must not attack our services via a denial-of-service
                  attack or a distributed denial-of-service attack.
                </li>
              </ul>
            </li>
            <li>
              <strong>Privacy Policy</strong>
              <ul>
                <li>
                  We are committed to protecting your privacy. Our privacy
                  policy explains how we collect, use, and disclose information
                  about you.
                </li>
                <li>
                  By using our services, you consent to our collection and use
                  of your information as described in our privacy policy.
                </li>
                <li>
                  We may update our privacy policy from time to time. We will
                  notify you of any changes by posting the new privacy policy on
                  our website.
                </li>
              </ul>
            </li>
            <li>
              <strong>Intellectual Property</strong>
              <ul>
                <li>
                  All content included on the service, such as text, graphics,
                  logos, images, and software, is the property of our company or
                  its content suppliers and protected by international copyright
                  laws.
                </li>
                <li>
                  The compilation of all content on this site is the exclusive
                  property of our company and protected by international
                  copyright laws.
                </li>
                <li>
                  You may not modify, copy, distribute, transmit, display,
                  perform, reproduce, publish, license, create derivative works
                  from, transfer, or sell any information, software, products,
                  or services obtained from our services without our prior
                  written consent.
                </li>
              </ul>
            </li>
            <li>
              <strong>Termination</strong>
              <ul>
                <li>
                  We may terminate or suspend access to our service immediately,
                  without prior notice or liability, for any reason whatsoever,
                  including without limitation if you breach the terms.
                </li>
                <li>
                  All provisions of the terms which by their nature should
                  survive termination shall survive termination, including,
                  without limitation, ownership provisions, warranty
                  disclaimers, indemnity, and limitations of liability.
                </li>
                <li>
                  Upon termination, your right to use the service will
                  immediately cease.
                </li>
              </ul>
            </li>
            <li>
              <strong>Governing Law</strong>
              <ul>
                <li>
                  These terms shall be governed and construed in accordance with
                  the laws of [Your Country], without regard to its conflict of
                  law provisions.
                </li>
                <li>
                  Our failure to enforce any right or provision of these terms
                  will not be considered a waiver of those rights.
                </li>
                <li>
                  If any provision of these terms is held to be invalid or
                  unenforceable by a court, the remaining provisions of these
                  terms will remain in effect.
                </li>
                <li>
                  These terms constitute the entire agreement between us
                  regarding our service, and supersede and replace any prior
                  agreements we might have had between us regarding the service.
                </li>
              </ul>
            </li>
            <li>
              <strong>Contact Information</strong>
              <ul>
                <li>
                  If you have any questions about these terms, please contact us
                  at support@example.com.
                </li>
                <li>
                  For general inquiries, you can reach us at info@example.com.
                </li>
                <li>
                  For support, please email support@example.com or call our
                  support hotline at (123) 456-7890.
                </li>
              </ul>
            </li>
          </ol>
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default TermsAndConditions;

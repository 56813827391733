import axios from 'axios';
import { Cookies } from 'react-cookie';
import env from 'react-dotenv';

const API_BASE_URL = env.REACT_APP_API_BASE_URL;
const cookies = new Cookies();

const HandleUnauthorized = async () => {
  cookies.remove('user', { path: '/' });
  cookies.remove('super_user', { path: '/' });
  cookies.remove('token', { path: '/' });
  cookies.remove('role', { path: '/' });
  cookies.remove('settings', { path: '/' });
};

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      HandleUnauthorized();
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use((config) => {
  const token = cookies.get('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;

import React from 'react';

interface UploadIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

const UploadIcon: React.FC<UploadIconProps> = ({
  width = 24,
  height = 24,
  fill = '#000000',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.75 3.57812V18.25C12.75 18.6719 12.375 19 12 19C11.5781 19 11.25 18.6719 11.25 18.25V3.57812L6.51562 8.3125C6.23438 8.59375 5.71875 8.59375 5.4375 8.3125C5.15625 8.03125 5.15625 7.51562 5.4375 7.23438L11.4375 1.23438C11.7188 0.953125 12.2344 0.953125 12.5156 1.23438L18.5156 7.23438C18.7969 7.51562 18.7969 8.03125 18.5156 8.3125C18.2344 8.59375 17.7188 8.59375 17.4375 8.3125L12.75 3.57812ZM9.75 17.5H3C2.15625 17.5 1.5 18.2031 1.5 19V22C1.5 22.8438 2.15625 23.5 3 23.5H21C21.7969 23.5 22.5 22.8438 22.5 22V19C22.5 18.2031 21.7969 17.5 21 17.5H14.25V16H21C22.6406 16 24 17.3594 24 19V22C24 23.6875 22.6406 25 21 25H3C1.3125 25 0 23.6875 0 22V19C0 17.3594 1.3125 16 3 16H9.75V17.5ZM18 20.5C18 19.8906 18.4688 19.375 19.125 19.375C19.7344 19.375 20.25 19.8906 20.25 20.5C20.25 21.1562 19.7344 21.625 19.125 21.625C18.4688 21.625 18 21.1562 18 20.5Z" />
  </svg>
);

export default UploadIcon;

import { Grid2 } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  addToRoute,
  cancelAddress,
  getCompanyDrivers,
  getCompanyLocations,
  getDriverRoute,
  skipAddress,
} from '../../../services/apiService';
import useDebounce from '../../../utils/useDebounce';
import { format } from 'date-fns';

import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/circle-xmark.svg';
import { ReactComponent as SkipIcon } from '../../../assets/icons/forward.svg';

import RouteTableComponent from '../../../components/Table/RouteTableComponent';
import { GetDriverRouteResponse } from '../../../interfaces/Driver/Response/getDriverRoute';
import { GetDriverRouteRequest } from '../../../interfaces/Driver/Request/getDriverRoute';
import { useNavigate, useParams } from 'react-router-dom';
import { SkipAddressRequest } from '../../../interfaces/Route/Request/skipAddress';
import { TripResponse } from '../../../interfaces/Route/Response/trip';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { CancelAddressRequest } from '../../../interfaces/Route/Request/cancelAddress';
import { CompanyLocationsRequest } from '../../../interfaces/Location/Request/companyLocations';
import { useCookies } from 'react-cookie';
import { CompanyLocationsResponse } from '../../../interfaces/Location/Response/companyLocations';
import AddNewTripModal from './Components/AddNewTripModal';
import { CompanyDriversRequest } from '../../../interfaces/Driver/Request/companyDrivers';
import { CompanyDriversResponse } from '../../../interfaces/Driver/Response/companyDrivers';
import { setLocation } from '../../../utils/locationUtil';
import { useTranslation } from 'react-i18next';
import SkipAddressModal from './Components/SkipAddressModal';
import CancelAddressTripModal from './Components/CancelAddressModal';

function RoutesPage() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [route, setRoute] = useState<GetDriverRouteResponse>();
  const [selectedRow, setSelectedRow] = useState<TripResponse>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columnFieldMapping = {
    'Destination': 'end_location',
    'Status': 'status',
    'Assigned Driver': 'assigned_driver',
    'Date Actioned': 'date_actioned',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['35%', '30%', '15%', '15%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'View Destination on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedRow(row as unknown as TripResponse);
        setLocation(
          parseFloat(row.end_location_latitude as string),
          parseFloat(row.end_location_longitude as string)
        );
        navigate('/');
      },
    },
    {
      label: 'Skip Address',
      icon: <SkipIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenSkipAddress(true);
        setSelectedRow(row as unknown as TripResponse);
      },
    },
    {
      label: 'Cancel',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenCancelAddress(true);
        setSelectedRow(row as unknown as TripResponse);
      },
    },
  ];

  const handlePlusIconClick = () => {
    setOpenAddRouteItem(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleTimeChange = (dataInput: GetDriverRouteResponse) => {
    dataInput.trips.forEach((trip) => {
      if (trip.date_actioned !== '') {
        trip.date_actioned = format(
          new Date(trip.date_actioned),
          'HH:mm - dd MMM yyyy'
        );
      }
    });

    return dataInput;
  };

  useEffect(() => {
    const fetchRoute = async () => {
      try {
        const payload: GetDriverRouteRequest = {
          vehicle_id: id ?? '',
          search_term: debouncedSearchValue,
        };

        const data = await getDriverRoute(payload);
        setRoute(handleTimeChange(data));
      } catch {
        setSnackbarMessage('Vehicle does not exist.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchRoute();
  }, [debouncedSearchValue, id, trigger]);

  // Methods pertaining to skip address

  const [openSkipAddress, setOpenSkipAddress] = useState(false);
  const [formValuesSkipAddress, setFormValuesSkipAddress] = useState({
    reAddAddress: false,
  });

  const handleSkipAddress = () => {
    const skipAddress_ = async () => {
      try {
        const payload: SkipAddressRequest = {
          route_id: route?.route_id ?? '',
          trip_id: selectedRow?.trip_id ?? '',
          reAdd: formValuesSkipAddress.reAddAddress,
        };
        await skipAddress(payload);

        setTrigger(!trigger);
        setOpenSkipAddress(false);
        setSnackbarMessage('Address successfully skipped.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch {
        setSnackbarMessage('Error skipping address.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    skipAddress_();
  };

  useEffect(() => {
    if (openSkipAddress) {
      setFormValuesSkipAddress({ reAddAddress: false });
    }
  }, [openSkipAddress]);

  // methods pertaining to cancel address

  const [openCancelAddress, setOpenCancelAddress] = useState(false);

  const handleCancelAddress = () => {
    const cancelAddress_ = async () => {
      try {
        const payload: CancelAddressRequest = {
          route_id: route?.route_id ?? '',
          trip_id: selectedRow?.trip_id ?? '',
        };
        await cancelAddress(payload);

        setTrigger(!trigger);
        setOpenCancelAddress(false);
        setSnackbarMessage('Address successfully cancelled.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch {
        setSnackbarMessage('Error cancelling address.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    cancelAddress_();
  };

  // methods pertaining to add new route item

  const [openAddRouteItem, setOpenAddRouteItem] = useState(false);
  const [allLocations, setAllLocations] = useState<CompanyLocationsResponse[]>(
    []
  );
  const [allDrivers, setAllDrivers] = useState<CompanyDriversResponse[]>([]);
  const [formValues, setFormValues] = useState({
    location: '',
    driver: '',
  });

  useEffect(() => {
    const getAllLocations = async () => {
      try {
        const payload: CompanyLocationsRequest = {
          company_id: cookies.user.company_id,
          search_term: '',
        };
        const response = await getCompanyLocations(payload);
        setAllLocations(response);
      } catch {
        setSnackbarMessage('Error getting locations.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    getAllLocations();
  }, [cookies.user.company_id]);

  useEffect(() => {
    const getAllDrivers = async () => {
      try {
        const payload: CompanyDriversRequest = {
          company_id: cookies.user.company_id,
          search_term: '',
        };
        const response = await getCompanyDrivers(payload);
        setAllDrivers(response);
      } catch {
        setSnackbarMessage('Error getting drivers.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    getAllDrivers();
  }, [cookies.user.company_id]);

  const handleAddNewTrip = async () => {
    const payload = {
      route_id: route?.route_id ?? '',
      start_location_id: formValues.location,
      end_location_id: formValues.location,
      expected_departure_time: new Date().toISOString(),
      expected_arrival_time: new Date().toISOString(),
      driver_id: formValues.driver === '' ? null : formValues.driver,
    };

    const response = await addToRoute(payload);

    setOpenAddRouteItem(false);
    setFormValues({ location: '', driver: '' });
    if (response.error === null) {
      setSnackbarMessage('Trip successfully added.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTrigger(!trigger);
    } else {
      setSnackbarMessage('Error adding address.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <RouteTableComponent
          title={`${
            route?.vehicle_alias
              ? `${t('Route for')} ${route.vehicle_alias}`
              : route?.vehicle_registration
              ? `${t('Route for')} ${route.vehicle_registration}`
              : t('Vehicle NOT FOUND')
          }`}
          searchValue={searchValue}
          onPlusIconClick={handlePlusIconClick}
          setData={() => {}}
          columns={columns}
          data={route?.trips ?? []}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          onSearchValueChange={handleSearchChange}
          columnWidths={columnWidths}
        />
      </Grid2>

      <SkipAddressModal
        open={openSkipAddress}
        onClose={() => setOpenSkipAddress(false)}
        formValues={formValuesSkipAddress}
        setFormValues={setFormValuesSkipAddress}
        onSubmit={handleSkipAddress}
      />

      <CancelAddressTripModal
        open={openCancelAddress}
        onClose={() => setOpenCancelAddress(false)}
        onSubmit={handleCancelAddress}
      />

      <AddNewTripModal
        open={openAddRouteItem}
        onClose={() => setOpenAddRouteItem(false)}
        locations={allLocations}
        formValues={formValues}
        setFormValues={setFormValues}
        drivers={allDrivers}
        onSubmit={handleAddNewTrip}
      />
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </Grid2>
  );
}

export default RoutesPage;

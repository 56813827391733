import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DriverCards from './Components/DriverCards/DriverCards';
import MapComponent from '../../../components/Map/MapComponent';
import { CompanyDriverCardsResponse } from '../../../interfaces/Driver/Response/companyDriverCards';
import { getCompanyDriverCards } from '../../../services/apiService';
import { useCookies } from 'react-cookie';
import { CompanyDriverCardsRequest } from '../../../interfaces/Driver/Request/companyDriverCards';

function Dashboard() {
  const isSmallViewport = useMediaQuery('(max-width:1500px)');

  const drawerWidth = isSmallViewport ? '350px' : '425px';
  const [cookies] = useCookies(['user']);

  const [drivers, setDrivers] = useState<CompanyDriverCardsResponse[]>([]);
  // this needs to be replaced by an endpoint that manages marker data
  useEffect(() => {
    const fetchDrivers = async () => {
      const payload: CompanyDriverCardsRequest = {
        company_id: cookies.user.company_id,
        search: '',
      };
      try {
        const data = await getCompanyDriverCards(payload);
        setDrivers(data);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
        }}
      >
        <MapComponent drivers={drivers} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: drawerWidth,
          height: '100vh',
          zIndex: 1,
          overflowY: 'auto',
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
        }}
      >
        <DriverCards />
      </Box>
    </Box>
  );
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import modalTheme from '../../../../theme/modalTheme';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  CountryCode,
} from 'libphonenumber-js';
import { CompanyLocationsResponse } from '../../../../interfaces/Location/Response/companyLocations';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';
import StyledPhoneInput from '../../../../components/PhoneNumber/StyledPhoneInput';
import { useTranslation } from 'react-i18next';

interface AddOperatorModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formValues: {
    first_name: string;
    surname: string;
    contact_number: string;
    email: string;
    location_id: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      first_name: string;
      surname: string;
      contact_number: string;
      email: string;
      location_id: string;
    }>
  >;
  locations: CompanyLocationsResponse[];
  isEditMode?: boolean;
  operatorId?: string;
}

const AddOperatorModal: React.FC<AddOperatorModalProps> = ({
  open,
  onClose,
  onSubmit,
  formValues,
  setFormValues,
  locations,
  isEditMode = false,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({
        first_name: '',
        surname: '',
        contact_number: '',
        email: '',
        location_id: '',
      });
      setSelectedCountry('za');
      setPhoneError('');
      setEmailError('');
    }
  }, [open, setFormValues]);

  const [selectedCountry, setSelectedCountry] = useState('za');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(true);
  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    if (email.trim() === '') {
      setEmailError('');
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError(t('Please enter a valid email address'));
    } else {
      setEmailError('');
    }
  };

  const validatePhoneNumber = (value: string, country: CountryData) => {
    const dialCode = country.dialCode;

    if (value === dialCode) {
      setPhoneError('');
      setIsPhoneEmpty(true);
      return;
    }

    try {
      parsePhoneNumber(value, country.countryCode.toUpperCase() as CountryCode);
      if (
        !isValidPhoneNumber(
          value,
          country.countryCode.toUpperCase() as CountryCode
        )
      ) {
        setPhoneError(t('Invalid phone number'));
        setIsPhoneEmpty(false);
      } else {
        setPhoneError('');
        setIsPhoneEmpty(false);
      }
    } catch {
      setPhoneError(t('Invalid phone number'));
      setIsPhoneEmpty(false);
    }
  };

  const handlePhoneChange = (value: string, country: CountryData) => {
    setFormValues({ ...formValues, contact_number: value });
    setSelectedCountry(country.countryCode);
    validatePhoneNumber(value, country);
  };

  const isFormValid =
    formValues.first_name.trim() !== '' &&
    formValues.surname.trim() !== '' &&
    formValues.contact_number.trim() !== '' &&
    phoneError === '' &&
    !isPhoneEmpty &&
    formValues.email.trim() !== '' &&
    emailError === '' &&
    formValues.location_id.trim() !== '';
  useEffect(() => {
    if (isEditMode) {
      setIsPhoneEmpty(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {isEditMode ? t('Edit Operator') : t('Add Operator')}
        </DialogTitle>
        <Typography variant="subtitle1">
          {isEditMode
            ? t('Edit the details below to update the Operator’s profile.')
            : t(
                'Operators are internal staff members who are permanently stationed at a fixed company location. By default they have permission to lock and unlock all devices that are within an approved GeoZone.'
              )}
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('First Name')}*
          </Typography>
          <TextField
            placeholder={t('Enter First Name')}
            type="text"
            value={formValues.first_name}
            onChange={(e) =>
              setFormValues({ ...formValues, first_name: e.target.value })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {'Surname'}*
          </Typography>
          <TextField
            placeholder={t('Enter Surname')}
            type="text"
            value={formValues.surname}
            onChange={(e) =>
              setFormValues({ ...formValues, surname: e.target.value })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Contact Number')}*
          </Typography>
          <Box sx={{ mb: phoneError ? 0 : 2 }}>
            <StyledPhoneInput
              country={selectedCountry}
              countryCodeEditable={false}
              value={formValues.contact_number}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: false,
                placeholder: '',
              }}
            />

            {phoneError && (
              <Typography
                variant="caption"
                color="#d32f2f"
                sx={{
                  margin: 0,
                  padding: 0,
                  display: 'block',
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: 2,
                }}
              >
                {phoneError}
              </Typography>
            )}
          </Box>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Email')}*
          </Typography>
          <TextField
            placeholder={t('Enter Email')}
            type="email"
            value={formValues.email}
            onChange={(e) => {
              setFormValues({ ...formValues, email: e.target.value });
              validateEmail(e.target.value);
            }}
            fullWidth
            error={emailError !== ''}
            helperText={emailError && emailError}
            sx={{
              'mb': 2,
              'backgroundColor': 'white',
              '& .MuiFormHelperText-root': {
                backgroundColor: '#FBFBFB',
                width: '100%',
                marginLeft: 0,
              },
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Location')}
          </Typography>
          <Autocomplete
            options={locations}
            getOptionLabel={(option) => option.name}
            value={
              locations.find((v) => v.id === formValues.location_id) || null
            }
            onChange={(event, newValue) => {
              setFormValues({ ...formValues, location_id: newValue?.id || '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Location"
                fullWidth
                sx={{ mb: 2, backgroundColor: 'white' }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            height: '14px',
                            width: '15px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {isEditMode
              ? t('Edit Operator Profile')
              : t('Create Operator Profile')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddOperatorModal;

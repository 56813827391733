import React from 'react';
import { Snackbar, Alert, SnackbarCloseReason } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SnackbarComponentProps {
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
  open: boolean;
  onClose: (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => void;
}

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  message,
  severity,
  duration = 2000,
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message ? t(message) : ''}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;

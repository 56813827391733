import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { GetUnusedSealsResponse } from '../../../../interfaces/Seals/Response/getUnusedSeals';
import { useTranslation } from 'react-i18next';

interface AddVehicleModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formValues: {
    vehicle_registration: string;
    vehicle_alias: string;
    seal_id: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      vehicle_registration: string;
      vehicle_alias: string;
      seal_id: string;
    }>
  >;
  unusedSeals: GetUnusedSealsResponse[];
}

const AddVehicleModal: React.FC<AddVehicleModalProps> = ({
  open,
  onClose,
  onSubmit,
  formValues,
  setFormValues,
  unusedSeals,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({
        vehicle_registration: '',
        vehicle_alias: '',
        seal_id: '',
      });
    }
  }, [open, setFormValues]);

  const { t } = useTranslation();
  const isFormValid = formValues.vehicle_registration.trim() !== '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Add New Vehicle')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'Complete the below details in order to add a new vehicle to your fleet'
          )}
          .
        </Typography>
        <DialogContent>
          <Typography variant="subtitle2">
            {t('Vehicle Registration')}*
          </Typography>
          <TextField
            placeholder={t('Vehicle Registration')}
            type="text"
            value={formValues.vehicle_registration}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                vehicle_registration: e.target.value,
              })
            }
            fullWidth
          />
          <Typography variant="subtitle2">{t('Vehicle Alias')}</Typography>
          <TextField
            placeholder={t('Vehicle Alias')}
            type="text"
            value={formValues.vehicle_alias}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                vehicle_alias: e.target.value,
              })
            }
            fullWidth
          />
          <Typography variant="subtitle2">
            {t('Assign Unused iMSeal')}
          </Typography>
          <Select
            fullWidth
            value={formValues.seal_id}
            onChange={(e) => {
              setFormValues({ ...formValues, seal_id: e.target.value });
            }}
          >
            <MenuItem value=" ">{t('Select iMSeal')}</MenuItem>
            {unusedSeals.map((seal) => (
              <MenuItem key={seal.id} value={seal.id}>
                {seal.serial_number}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {t('Confirm Adding Vehicle')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddVehicleModal;

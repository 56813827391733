import { Grid2 } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import { GetCompanySealsRequest } from '../../../interfaces/Seals/Request/getCompanySeals';
import { GetCompanySealsResponse } from '../../../interfaces/Seals/Response/getCompanySeals';
import {
  addCommand,
  assignSealToVehicle,
  getCompanySeals,
  getCompanyVehicles,
  unassignSealFromVehicle,
} from '../../../services/apiService';
import useDebounce from '../../../utils/useDebounce';

import { useCookies } from 'react-cookie';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as UnassignIcon } from '../../../assets/icons/circle-xmark.svg';
import { ReactComponent as TruckIcon } from '../../../assets/icons/truck.svg';
import { ReactComponent as UnlockIcon } from '../../../assets/icons/unlock.svg';
import { ReactComponent as CradleIcon } from '../../../assets/icons/up-from-dotted-line.svg';
import { CompanyVehiclesResponse } from '../../../interfaces/Vehicles/Response/companyVehicles';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import AssignSealToVehicleModal from './Components/AssignSealToVehicleModal';
import Locking from '../../../components/Locking/Locking';
import ConfirmSeal from './Components/ConfirmSeal';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UnassignSealModal from './Components/UnassignSealModal';
import UnlockCradleModal from './Components/UnlockCradleModal';

function Seals() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'role']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [seals, setSeals] = useState<GetCompanySealsResponse[]>([]);
  const [selectedRow, setSelectedRow] =
    useState<GetCompanySealsResponse | null>();
  const { t } = useTranslation();

  const columnFieldMapping = {
    'iMSeal Serial Number': 'serial_number',
    'Driver': 'driver_full_name',
    'Vehicle': 'vehicle_alias',
    'Status': 'status',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['20%', '14%', '50%', '14%'];

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Assign to Vehicle',
      icon: <TruckIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenAssignToVehicle(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
      },
      disabled: row.vehicle_id !== '',
    },
    {
      label: 'Unassign iMSeal',
      icon: <UnassignIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenUnassignSeal(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
      },
      disabled: row.vehicle_id === '',
    },
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedRow(row as unknown as GetCompanySealsResponse);
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
    },
    {
      label:
        row.left_lock === 'LOCKED'
          ? 'Unlock Left'
          : row.left_lock === 'UNLOCKED'
          ? 'Lock Left'
          : 'Processing...',
      icon: <UnlockIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(true);
        setCurrentLockState(row.left_lock === 'LOCKED');
        setSelectedSeal(row.serial_number as string);
      },
      disabled: row.left_lock === 'PROCESSING',
      isLock: true,
    },
    {
      label:
        row.right_lock === 'LOCKED'
          ? 'Unlock Right'
          : row.right_lock === 'UNLOCKED'
          ? 'Lock Right'
          : 'Processing...',
      icon: <UnlockIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenLocking(true);
        setLeftLock(false);
        setCurrentLockState(row.right_lock === 'LOCKED');
        setSelectedSeal(row.serial_number as string);
      },
      disabled: row.right_lock === 'PROCESSING',
      isLock: true,
    },
    {
      label:
        row.cradle_lock === 'LOCKED'
          ? 'Unlock Cradle'
          : row.cradle_lock === 'UNLOCKED'
          ? 'Lock Cradle'
          : 'Processing...',
      icon: <CradleIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOpenUnlockCradle(true);
        setSelectedRow(row as unknown as GetCompanySealsResponse);
        setSelectedSeal(row.serial_number as string);
      },
      disabled: row.cradle_lock === 'PROCESSING',
    },
  ];

  const handlePlusIconClick = () => {
    setOpenAddNew(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const fetchSeals = async () => {
      const payload: GetCompanySealsRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
      };
      try {
        const data = await getCompanySeals(payload);
        setSeals(data);
      } catch (error) {
        console.error('Error fetching seals:', error);
      }
    };

    fetchSeals();
  }, [debouncedSearchValue, cookies.user.company_id]);

  const hasProcessingState = (data: GetCompanySealsResponse[]) => {
    return data.some(
      (entry) =>
        entry.left_lock === 'PROCESSING' || entry.right_lock === 'PROCESSING'
    );
  };

  const pollApi = async () => {
    const payload: GetCompanySealsRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
    };
    try {
      const data = await getCompanySeals(payload);
      setSeals(data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (hasProcessingState(seals)) {
      interval = setInterval(() => {
        pollApi();
      }, 20000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [seals]);

  const refetchInfo = async () => {
    const fetchSeals = async () => {
      const payload: GetCompanySealsRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
      };
      try {
        const data = await getCompanySeals(payload);
        setSeals(data);
      } catch (error) {
        console.error('Error fetching seals:', error);
      }
    };
    const fetchVehicles = async () => {
      const payload = {
        company_id: cookies.user.company_id,
        search_term: '',
        exclude_seals: true,
      };
      try {
        const response = await getCompanyVehicles(payload);
        setVehicles(response);
      } catch {
        console.error('Error fetching vehicles');
      }
    };

    fetchVehicles();
    fetchSeals();
  };

  const [openAddNew, setOpenAddNew] = useState(false);

  // Methods pertaining to assign to vehicle seal

  const [openAssignToVehicle, setOpenAssignToVehicle] = useState(false);
  const [vehicles, setVehicles] = useState<CompanyVehiclesResponse[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState({
    vehicle: ' ',
  });

  useEffect(() => {
    const fetchVehicles = async () => {
      const payload = {
        company_id: cookies.user.company_id,
        search_term: '',
        exclude_seals: true,
      };
      try {
        const response = await getCompanyVehicles(payload);
        setVehicles(response);
      } catch {
        console.error('Error fetching vehicles');
      }
    };
    fetchVehicles();
  }, [cookies.user.company_id]);

  const handleAssignToVehicle = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_id: selectedRow?.id as string,
        vehicle_id: selectedVehicle.vehicle,
      };

      await assignSealToVehicle(payload);

      refetchInfo();
      setSelectedVehicle({ vehicle: ' ' });
      setOpenAssignToVehicle(false);
      setSnackbarMessage('Seal Assigned to Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch {
      setSnackbarMessage('Seal Assignment Failed');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Methods pertaining to unassign seal

  const [openUnassignSeal, setOpenUnassignSeal] = useState(false);

  const handleUnassignSeal = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_id: selectedRow?.id as string,
        vehicle_id: selectedRow?.vehicle_id as string,
      };

      await unassignSealFromVehicle(payload);

      refetchInfo();
      setSelectedVehicle({ vehicle: ' ' });
      setOpenUnassignSeal(false);
      setSnackbarMessage('Seal Unassigned from Vehicle');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch {
      setSnackbarMessage('Seal Unassignment Failed');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const [openLocking, setOpenLocking] = useState(false);
  const [leftLock, setLeftLock] = useState(false);
  const [currentLockState, setCurrentLockState] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState('');

  const [openUnlockCradle, setOpenUnlockCradle] = useState(false);

  const handleUnlockCradle = async () => {
    try {
      const payload = {
        actioned_by:
          cookies.user.first_name +
          ' ' +
          cookies.user.last_name +
          ' - ' +
          cookies.role,
        company_id: cookies.user.company_id,
        seal_serial_number: selectedRow?.serial_number as string,
        command_code: selectedRow?.cradle_lock === 'LOCKED' ? 'UC' : 'LK',
      };

      const response = await addCommand(payload);

      setOpenUnlockCradle(false);

      if (response.statusCode === 400) {
        setSnackbarMessage(response.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage(
          t(response.data.message) +
            ' - ' +
            t('Expected time to complete in seconds') +
            ': ' +
            Math.round(response.data.expectedPingResponseTime)
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage('Cradle Lock/Unlock Failed');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('iMSeal Locks')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={seals}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
        />
      </Grid2>

      <AssignSealToVehicleModal
        open={openAssignToVehicle}
        onClose={() => setOpenAssignToVehicle(false)}
        sealDetails={selectedRow || {}}
        vehicles={vehicles}
        formValues={selectedVehicle}
        setFormValues={setSelectedVehicle}
        onSubmit={handleAssignToVehicle}
      />

      <UnassignSealModal
        open={openUnassignSeal}
        onClose={() => setOpenUnassignSeal(false)}
        onSubmit={handleUnassignSeal}
      />

      <ConfirmSeal
        open={openAddNew}
        onClose={() => setOpenAddNew(false)}
        refetchInfo={refetchInfo}
      />

      <Locking
        open={openLocking}
        onClose={() => {
          setOpenLocking(false);
          refetchInfo();
        }}
        unlockLeft={leftLock}
        currentState={currentLockState}
        serial_number={selectedSeal}
        company_id={cookies.user.company_id}
      />

      <UnlockCradleModal
        open={openUnlockCradle}
        current_state={selectedRow?.cradle_lock === 'LOCKED'}
        onClose={() => setOpenUnlockCradle(false)}
        serial_number={selectedSeal}
        onSubmit={handleUnlockCradle}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Grid2>
  );
}

export default Seals;

import {
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/magnifying-glass.svg';
import { ReactComponent as EllipsVertIcon } from '../../assets/icons/ellipsis-vertical.svg';
import Pagination from '../Pagination/Pagination';
import NoDataState from './NoDataState';
import { useTranslation } from 'react-i18next';

interface RouteTableComponentProps {
  title: string;
  searchValue: string;
  columns: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<{ [key: string]: any }>;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
  columnFieldMapping: { [key: string]: string };
  columnWidths: string[];
  actions: (row: {
    [key: string]: unknown;
  }) => Array<{ label: string; icon: ReactNode; onClick: () => void }>;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPlusIconClick: () => void;
}

const RouteTableComponent: React.FC<RouteTableComponentProps> = ({
  title,
  searchValue,
  columns,
  data,
  columnFieldMapping,
  columnWidths,
  //setData,
  actions,
  onSearchValueChange,
  onPlusIconClick,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: unknown;
  }>(null);
  const [timePassed, setTimePassed] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setTimePassed(true);
    }, 1500);
  }, []);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setLastUpdatedTime(getCurrentTime());
  }, [data]);

  const calculateTotalPages = (totalEntries: number, rowsPerPage: number) => {
    return Math.ceil(totalEntries / rowsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = useMemo(() => {
    return data.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [data, currentPage, rowsPerPage]);

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: { [key: string]: unknown }
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const calculateRowsPerPage = () => {
    const rowHeight = 60;
    const headerHeight = 180;
    const footerHeight = 180;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    return Math.floor(availableHeight / rowHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      const newRowsPerPage = calculateRowsPerPage();
      setRowsPerPage(newRowsPerPage);
      setCurrentPage(1);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTotalPages(calculateTotalPages(data.length, rowsPerPage));
  }, [rowsPerPage, data]);

  return (
    <Grid2
      container
      display={'flex'}
      direction={'column'}
      spacing={2}
      sx={{
        height: '100%',
        minHeight: '250px',
        maxWidth: '100%',
      }}
    >
      <Grid2 container display={'flex'} alignItems={'baseline'}>
        <Typography variant="h4">{title}</Typography>
        <Typography
          style={{
            fontSize: '12px',
            color: '#86BE02',
          }}
        >
          {t('Last updated at')} {lastUpdatedTime}
        </Typography>
      </Grid2>
      <Grid2
        display={'flex'}
        alignItems={'center'}
        sx={{
          height: '40px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'rgba(131, 131, 131, 0.4)',
          minWidth: '100%',
        }}
      >
        <PlusIcon
          onClick={onPlusIconClick}
          cursor={'pointer'}
          style={{
            width: '16px',
            height: '16px',
            marginLeft: '10px',
            padding: '8px 4px',
          }}
        />
        <Grid2
          container
          display={'flex'}
          alignItems={'center'}
          sx={{
            height: '100%',
            width: '100%',
            borderLeft: '1px solid rgba(131, 131, 131, 0.4)',
            marginLeft: '10px',
          }}
        >
          <Grid2
            display={'flex'}
            alignItems={'center'}
            sx={{
              marginLeft: '10px',
            }}
          >
            <SearchIcon
              style={{
                fill: '#838383',
                width: '15px',
                height: '15px',
                marginRight: '10px',
              }}
            />
          </Grid2>
          <Grid2
            sx={{
              width: '80%',
            }}
          >
            <TextField
              sx={{
                'width': '100%',
                'padding': '0px',
                '& .MuiOutlinedInput-root': {
                  'padding': '0px',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '0px',
                },
              }}
              value={searchValue}
              onChange={onSearchValueChange}
              placeholder={t('Search')}
              variant="outlined"
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2>
        {data.length === 0 && timePassed ? (
          <NoDataState />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
            }}
          >
            <Table sx={{ borderSpacing: '0 8px', borderCollapse: 'separate' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    key={'index'}
                    style={{
                      width: '1%',
                      borderBottom: 'none',
                      color: 'rgba(131, 131, 131, 0.4)',
                    }}
                  ></TableCell>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        width: columnWidths[index],
                        borderBottom: 'none',
                        color: 'rgba(131, 131, 131, 0.4)',
                      }}
                    >
                      {t(column)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor:
                        paginatedData[rowIndex].status === 'ACTIVE'
                          ? '#F0FFCD'
                          : paginatedData[rowIndex].status === 'COMPLETE' ||
                            paginatedData[rowIndex].status === 'CANCELLED' ||
                            paginatedData[rowIndex].status === 'SKIPPED'
                          ? '#fff'
                          : rowIndex % 2 === 1
                          ? '#F5F5F5'
                          : '#FBFBFB',
                    }}
                  >
                    <TableCell
                      key={rowIndex + 1 + (currentPage - 1) * rowsPerPage}
                      style={{
                        width: '1%',
                        borderBottom: 'none',
                        padding: '8px 16px',
                        borderRadius: '16px 0 0 16px',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#000000',
                          fontSize: '14px',
                        }}
                      >
                        {rowIndex + 1 + (currentPage - 1) * rowsPerPage}
                      </Typography>
                    </TableCell>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          width: columnWidths[colIndex],
                          borderBottom: 'none',
                          padding: '8px 16px',
                        }}
                      >
                        {row[columnFieldMapping[column]] === null ||
                        row[columnFieldMapping[column]] === '' ||
                        row[columnFieldMapping[column]] === ' ' ? (
                          '-'
                        ) : column === 'Destination' &&
                          (row[columnFieldMapping['Status']] === 'SKIPPED' ||
                            row[columnFieldMapping['Status']] ===
                              'CANCELLED') ? (
                          <Typography
                            fontSize={'0.875rem'}
                            sx={{
                              textDecoration: 'line-through',
                            }}
                          >
                            {row[columnFieldMapping[column]]}
                          </Typography>
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'ACTIVE' ? (
                          <Typography color="#000" fontSize={'0.875rem'}>
                            • {t('Active')}
                          </Typography>
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'UPCOMING' ? (
                          <Typography color="#000" fontSize={'0.875rem'}>
                            • {t('Upcoming')}
                          </Typography>
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'COMPLETE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Completed')}
                          </Typography>
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'SKIPPED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Skipped')}
                          </Typography>
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'CANCELLED' ? (
                          <Typography color="#DD3333" fontSize={'0.875rem'}>
                            • {t('Cancelled')}
                          </Typography>
                        ) : (
                          row[columnFieldMapping[column]]
                        )}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: 'none',
                        borderRadius: '0 16px 16px 0',
                        padding: '8px',
                      }}
                    >
                      <IconButton
                        onClick={(event) => handleActionClick(event, row)}
                      >
                        <EllipsVertIcon
                          style={{
                            fill: '#000000',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow === row}
                        onClose={handleClose}
                        sx={{
                          '& .MuiPaper-root': {
                            borderRadius: '20px',
                          },
                        }}
                      >
                        {actions(row)
                          .filter((action) => {
                            const status = row[columnFieldMapping['Status']];
                            if (
                              ['SKIPPED', 'COMPLETE', 'CANCELLED'].includes(
                                status
                              )
                            ) {
                              return (
                                action.label === t('View Destination on Map')
                              );
                            }
                            return true;
                          })
                          .map((action, index) => (
                            <MenuItem
                              key={index}
                              disabled={
                                row[columnFieldMapping['Status']] ===
                                  'ACTIVE' &&
                                action.label === 'Skip Address' &&
                                rowIndex === 0
                              }
                              onClick={() => {
                                action.onClick();
                                handleClose();
                              }}
                              sx={{
                                padding: '12px 20px',
                              }}
                            >
                              {action.icon}
                              {t(action.label)}
                            </MenuItem>
                          ))}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid2>
      <Grid2 flexGrow={1}></Grid2>
      <Grid2>
        {data.length === 0 ? null : (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Grid2>
    </Grid2>
  );
};

export default RouteTableComponent;

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (email.trim() === '') {
    return true; // No error for empty strings
  }
  return emailRegex.test(email);
};

export const validatePasswordSignIn = (password: string): boolean => {
  return password.length >= 1;
};

export const validateContactNumber = (contactNumber: string): boolean => {
  const contactNumberRegex = /^[\d+\-\s]{10,}$/;
  return contactNumberRegex.test(contactNumber);
};

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Login } from '../../../services/userService';
import gritLogo from '../../../assets/icons/grit-logo.svg';
import MegaFortress from '../../../assets/images/MegaFortress.png';
import {
  validateEmail,
  validatePasswordSignIn,
} from '../../../utils/validationUtils';
import { useTranslation } from 'react-i18next';

const SignUpComponent: FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCookie] = useCookies([
    'user',
    'super_user',
    'token',
    'role',
    'settings',
  ]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordError(!validatePasswordSignIn(newPassword));
  };

  const isFormValid =
    !emailError && !passwordError && email !== '' && password !== '';

  const handleSignIn = async () => {
    if (isFormValid) {
      setIsLoading(true);
      setTimeout(async () => {
        try {
          const response = await Login({ email, password });
          setCookie('user', response.data.user, { path: '/' });
          setCookie('super_user', response.data.user.super_user, { path: '/' });
          setCookie('role', response.data.user.role, { path: '/' });
          setCookie('token', response.data.token, { path: '/' });
          setCookie('settings', response.data.settings, { path: '/' });
          if (response.data.user.super_user) {
            navigate('/admin/clients');
          } else {
            navigate('/');
          }
        } catch (error) {
          setIsLoading(false);
          console.error('Login failed:', error);
          setLoginError(true);
          setTimeout(async () => {
            setLoginError(false);
          }, 1500);
        }
      }, 1000);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };
  const handleContactUsClick = () => {
    const body = encodeURIComponent(
      `Hi GritCargo Team,

I am interested in discussing potential business opportunities with GritCargo and would love to learn more about your services. Please contact me at your earliest convenience.

Best regards,
[User Name]
[Company Name]
[Contact Information]`
    );

    window.location.href = `mailto:support@47660588.hubspot-inbox.com?subject=Business Inquiry - Request for Membership&body=${body}`;
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        minWidth: '30vw',
        maxWidth: '30vw',
        padding: '50px 70px',
        borderRadius: '16px',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        textAlign: 'center',
        overflow: 'hidden',
        border: '2px solid #B4EE2B',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          padding: '0px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '24px',
          }}
        >
          <img
            src={MegaFortress}
            alt="MegaFortress Logo"
            style={{
              width: '104px',
              height: '116.73px',
              paddingBottom: '20px',
            }}
          />

          <img
            src={gritLogo}
            alt="grit logo"
            style={{
              height: 'auto',
              width: '100%',
              maxWidth: '30vw',
            }}
          />
        </Box>

        {!isLoading && (
          <>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px',
                textAlign: 'center',
                color: '#000',
              }}
            >
              {t('Sign In')}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '28px',
                textAlign: 'center',
                color: '#838383',
              }}
            >
              {t('Use your Email to sign in')}
            </Typography>
          </>
        )}
      </Box>

      {isLoading ? (
        <CircularProgress sx={{ color: '#B4EE2B' }} size={80} />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <TextField
              placeholder={t('Email')}
              fullWidth
              value={email}
              onChange={handleEmailChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSignIn();
                }
              }}
              sx={{
                'backgroundColor': '#FFFFFF',
                'borderRadius': '8px',
                'marginBottom': '20px',
                '& .MuiOutlinedInput-root': {
                  'borderColor': '#C7C7C7',
                  '& fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C7C7C7',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '14px 14px',
                  color: '#000',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
                '& .MuiInputLabel-root': {
                  color: '#C7C7C7',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#C7C7C7',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#C7C7C7',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
              }}
            />

            <TextField
              placeholder={t('Password')}
              type="password"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSignIn();
                }
              }}
              sx={{
                'backgroundColor': '#FFFFFF',
                'borderRadius': '8px',
                'marginBottom': '20px',
                '& .MuiOutlinedInput-root': {
                  'borderColor': '#C7C7C7',
                  '& fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C7C7C7',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '14px 14px',
                  color: '#000',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
                '& .MuiInputLabel-root': {
                  color: '#C7C7C7',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#C7C7C7',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#C7C7C7',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
              }}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '20px',
              }}
            >
              <Typography
                sx={{
                  color: emailError || loginError ? 'red' : 'transparent',
                  fontSize: '14px',
                  marginRight: 'auto',
                  visibility: emailError || loginError ? 'visible' : 'hidden',
                }}
              >
                {emailError
                  ? t('Must be a valid email')
                  : loginError
                  ? t('Invalid login credentials')
                  : ' '}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '14.2px',
                  fontWeight: 400,
                  lineHeight: '20.28px',
                  textAlign: 'right',
                  color: '#86BE02',
                  cursor: 'pointer',
                }}
                onClick={handleForgotPassword}
              >
                {t('Forgot Password')}?
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0px 0px 24px',
              width: '100%',
            }}
          >
            <Button
              sx={{
                backgroundColor: isFormValid ? '#000000' : '#EEEEEE',
                color: isFormValid ? '#FFFFFF' : '#838383',
                padding: '16px 24px',
                borderRadius: '16px',
                width: '100%',
                fontSize: '18px',
                lineHeight: '28px',
                fontFamily: "'Open Sans', sans-serif",
                textAlign: 'center',
                textTransform: 'none',
                display: 'inline-block',
                position: 'relative',
                cursor: isFormValid ? 'pointer' : 'not-allowed',
              }}
              disabled={!isFormValid}
              onClick={handleSignIn}
            >
              {t('Sign In')}
            </Button>
          </Box>

          <Typography
            sx={{
              fontSize: '14px',
              color: '#000000',
              textAlign: 'center',
              lineHeight: '20px',
              fontFamily: 'Open Sans',
              fontWeight: 600,
            }}
          >
            {t('Not a member')}?{' '}
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                color: '#86BE02',
                cursor: 'pointer',
                fontFamily: 'Open Sans',
                fontWeight: 600,
                lineHeight: '20px',
              }}
              onClick={handleContactUsClick}
            >
              {t('Contact Us')}
            </Typography>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default SignUpComponent;

import React, { useEffect, useMemo, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import {
  createCompanyOperator,
  deleteCompanyOperator,
  getCompanyLocations,
  getCompanyOperators,
  updateCompanyOperator,
} from '../../../services/apiService';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';
import { ReactComponent as DriverCardIcon } from '../../../assets/icons/address-card.svg';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { useCookies } from 'react-cookie';

import { CompanyOperatorsResponse } from '../../../interfaces/Operators/Response/CompanyOperators';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import AddOperatorModal from './Components/AddOperatorModal';
import { CompanyLocationsResponse } from '../../../interfaces/Location/Response/companyLocations';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface EditOperatorDetails {
  id: string;
  first_name: string;
  surname: string;
  contact_number: string;
  email: string;
  location_id: string;
}

function Operators() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'role']);
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [selectedRow, setSelectedRow] =
    useState<CompanyOperatorsResponse | null>();

  const payload = useMemo(
    () => ({
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
    }),
    [cookies.user.company_id, debouncedSearchValue]
  );

  const [addOperatorFormValues, setAddOperatorFormValues] = useState({
    first_name: '',
    surname: '',
    contact_number: '',
    email: '',
    location_id: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const [operators, setOperators] = useState<CompanyOperatorsResponse[]>([]);
  const [locations, setLocations] = useState<CompanyLocationsResponse[]>([]);

  const [openAddOperatorModal, setOpenAddOperatorModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [operatorToDelete, setOperatorToDelete] =
    useState<CompanyOperatorsResponse | null>(null);

  const columnFieldMapping = {
    'Name': 'full_name',
    'Contact Number': 'contact_number',
    'Email': 'email',
    'Fixed Location': 'location',
  };
  const columns = Object.keys(columnFieldMapping);
  const columnWidths = ['20%', '15%', '20%', '20%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Edit Operator Details',
      icon: <DriverCardIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        const transformedRow: EditOperatorDetails = {
          id: row.id as string,
          first_name: row.first_name as string,
          surname: row.last_name as string,
          contact_number: row.contact_number as string,
          email: row.email as string,
          location_id: row.location_id as string,
        };
        setSelectedRow(row as unknown as CompanyOperatorsResponse);
        handleEditOperatorClick(transformedRow);
      },
    },
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setSelectedRow(row as unknown as CompanyOperatorsResponse);
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
    },
    {
      label: 'Delete Operator',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setOperatorToDelete(row as unknown as CompanyOperatorsResponse);
        setOpenDeleteModal(true);
      },
    },
  ];

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const data = await getCompanyOperators(payload);
        setOperators(data);
      } catch {
        setSnackbarMessage('Failed to fetch operators');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchOperators();
  }, [payload]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const payload = {
          company_id: cookies.user.company_id,
          search_term: '',
        };
        const data = await getCompanyLocations(payload);
        setLocations(data);
      } catch {
        setSnackbarMessage('Failed to fetch locations');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchLocations();
  }, [cookies.user.company_id]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleAddNewOperator = async () => {
    try {
      const operatorData = {
        first_name: addOperatorFormValues.first_name,
        last_name: addOperatorFormValues.surname,
        contact_number: addOperatorFormValues.contact_number,
        email: addOperatorFormValues.email,
        company_id: payload.company_id,
        location_id: addOperatorFormValues.location_id,
        password: 'password',
      };

      let response;
      if (isEditMode) {
        response = await updateCompanyOperator({
          ...operatorData,
          id: selectedRow?.id || '',
        });
      } else {
        response = await createCompanyOperator(operatorData);
      }

      if (response.error === null) {
        setSnackbarMessage(
          isEditMode
            ? 'Operator updated successfully.'
            : 'Operator added successfully.'
        );
        setSnackbarSeverity('success');
        const updatedOperators = await getCompanyOperators(payload);
        setOperators(updatedOperators);
      } else {
        setSnackbarMessage(response.message || 'An error occurred.');
        setSnackbarSeverity('error');
      }
    } catch {
      setSnackbarMessage(
        isEditMode
          ? 'An unexpected error occurred while updating the operator.'
          : 'An unexpected error occurred while adding the operator.'
      );
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
      closeAddOperatorModal();
      setAddOperatorFormValues({
        first_name: '',
        surname: '',
        contact_number: '',
        email: '',
        location_id: '',
      });
    }
  };

  const handleDeleteOperator = async () => {
    if (operatorToDelete) {
      try {
        const response = await deleteCompanyOperator(operatorToDelete.id!);
        if (response.success) {
          setOperators((prevOperator) =>
            prevOperator.filter(
              (operator) => operator.id !== operatorToDelete.id
            )
          );
          setSnackbarMessage('Operator deleted successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(response.message || 'Failed to delete operator');
          setSnackbarSeverity('error');
        }
      } catch {
        setSnackbarMessage('Could not delete operator');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenDeleteModal(false);
      }
    }
  };

  const closeAddOperatorModal = () => {
    setOpenAddOperatorModal(false);
    setIsEditMode(false);
  };

  const handlePlusIconClick = () => {
    setOpenAddOperatorModal(true);
  };

  const handleEditOperatorClick = (operator: EditOperatorDetails) => {
    setAddOperatorFormValues({
      first_name: operator.first_name || '',
      surname: operator.surname || '',
      contact_number: operator.contact_number || '',
      email: operator.email || '',
      location_id: operator.location_id || '',
    });
    setIsEditMode(true);
    setOpenAddOperatorModal(true);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('Operators')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={operators}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
        />
      </Grid2>

      <AddOperatorModal
        open={openAddOperatorModal}
        onClose={closeAddOperatorModal}
        onSubmit={handleAddNewOperator}
        formValues={addOperatorFormValues}
        setFormValues={setAddOperatorFormValues}
        locations={locations}
        isEditMode={isEditMode}
      />

      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteOperator}
        type="Operator"
        name={operatorToDelete?.full_name || ''}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Grid2>
  );
}

export default Operators;

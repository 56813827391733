import React, { useEffect, useRef, useState } from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import env from 'react-dotenv';
import { getLocation } from '../../utils/locationUtil';
// import CircleMarker from './MapComponents/Circle';
import DriverMarker from './MapComponents/DriverMarker';
import './MapComponent.css';
import { CompanyDriverCardsResponse } from '../../interfaces/Driver/Response/companyDriverCards';

const MAP_KEY = env.REACT_APP_MAPS_API_KEY;
const customStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#deff8f',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

interface MapComponentProps {
  drivers: CompanyDriverCardsResponse[];
}

const MapComponent: React.FC<MapComponentProps> = ({ drivers }) => {
  const [center, setCenter] = useState(getLocation());
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    const handleLocationChange = () => {
      setCenter(getLocation());
    };
    window.addEventListener('locationChange', handleLocationChange);

    return () => {
      window.removeEventListener('locationChange', handleLocationChange);
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo(center);
    }
  }, [center]);

  return (
    <APIProvider apiKey={MAP_KEY}>
      <Map
        center={center}
        defaultZoom={15}
        reuseMaps={true}
        minZoom={2.5}
        styles={customStyles}
        mapTypeControl={false}
      >
        {/* <CircleMarker position={geofenceLocation} mag={2.0} name="Avo office" description='Map Info display demonstration' /> */}
        {drivers.map((driver) => (
          <DriverMarker
            key={driver.id}
            position={{
              lat: Number(driver.latitude),
              lng: Number(driver.longitude),
            }}
            mag={2.4}
            name={driver.name}
            sealID={driver.serial_number}
            vehicleAlias={driver.vehicle_alias}
          />
        ))}
      </Map>
    </APIProvider>
  );
};

export default MapComponent;

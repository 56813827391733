import React, { useEffect, useState } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { CountryData } from 'react-phone-input-2';
import StyledPhoneInput from '../../../../components/PhoneNumber/StyledPhoneInput';
import { useTranslation } from 'react-i18next';

interface AddClientModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formValues: {
    name: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_number: string;
    email: string;
    status: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      name: string;
      contact_first_name: string;
      contact_last_name: string;
      contact_number: string;
      email: string;
      status: string;
    }>
  >;
}

const AddClientModal: React.FC<AddClientModalProps> = ({
  open,
  onClose,
  onSubmit,
  formValues,
  setFormValues,
}) => {
  const [selectedCountry, setSelectedCountry] = useState('za');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(true);
  const isFormValid =
    formValues.name.trim() !== '' &&
    formValues.contact_first_name.trim() !== '' &&
    formValues.contact_last_name.trim() !== '' &&
    formValues.contact_number.trim() !== '' &&
    formValues.email.trim() !== '' &&
    phoneError === '' &&
    !isPhoneEmpty &&
    formValues.email.trim() !== '' &&
    emailError === '';

  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    if (email.trim() === '') {
      setEmailError('');
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError(t('Please enter a valid email address'));
    } else {
      setEmailError('');
    }
  };

  const validatePhoneNumber = (value: string, country: CountryData) => {
    const dialCode = country.dialCode;

    if (value === dialCode) {
      setPhoneError('');
      setIsPhoneEmpty(true);
      return;
    }

    try {
      parsePhoneNumber(value, country.countryCode.toUpperCase() as CountryCode);
      if (
        !isValidPhoneNumber(
          value,
          country.countryCode.toUpperCase() as CountryCode
        )
      ) {
        setPhoneError(t('Invalid phone number'));
        setIsPhoneEmpty(false);
      } else {
        setPhoneError('');
        setIsPhoneEmpty(false);
      }
    } catch {
      setPhoneError(t('Invalid phone number'));
      setIsPhoneEmpty(false);
    }
  };

  const handlePhoneChange = (value: string, country: CountryData) => {
    setFormValues({ ...formValues, contact_number: value });
    setSelectedCountry(country.countryCode);
    validatePhoneNumber(value, country);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open]);

  const handleClose = () => {
    setPhoneError('');
    setEmailError('');
    onClose();
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('Add New Client')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'The contact person will receive an email with instructions to complete their account creation process'
          )}
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Company Name')}*
          </Typography>
          <TextField
            placeholder="Enter Company"
            type="text"
            value={formValues.name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Contact Person First Name')}*
          </Typography>
          <TextField
            placeholder="Enter First Name"
            type="text"
            value={formValues.contact_first_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                contact_first_name: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Contact Person Last Name')}*
          </Typography>
          <TextField
            placeholder={t('Enter Last Name')}
            type="text"
            value={formValues.contact_last_name}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                contact_last_name: e.target.value,
              })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Contact Number')}*
          </Typography>
          <Box sx={{ mb: phoneError ? 0 : 2 }}>
            <StyledPhoneInput
              country={selectedCountry}
              countryCodeEditable={false}
              value={formValues.contact_number}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: false,
                placeholder: '',
              }}
            />

            {phoneError && (
              <Typography
                variant="caption"
                color="#d32f2f"
                sx={{
                  margin: 0,
                  padding: 0,
                  display: 'block',
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: 2,
                }}
              >
                {phoneError}
              </Typography>
            )}
          </Box>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Contact Email')}*
          </Typography>
          <TextField
            placeholder={t('Enter Email')}
            type="email"
            value={formValues.email}
            onChange={(e) => {
              setFormValues({ ...formValues, email: e.target.value });
              validateEmail(e.target.value);
            }}
            fullWidth
            error={emailError !== ''}
            helperText={emailError && emailError}
            sx={{
              'mb': 2,
              'backgroundColor': 'white',
              '& .MuiFormHelperText-root': {
                backgroundColor: '#FBFBFB',
                width: '100%',
                marginLeft: 0,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={!isFormValid}
          >
            {t('Create Client')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddClientModal;

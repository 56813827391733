import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import CustomIcon from '../../assets/icons/not-found-car.svg';
import StripIcon from '../../assets/icons/not-found-strip.svg';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const [cookies] = useCookies(['token', 'super_user', 'isAdmin']);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (cookies.super_user || cookies.isAdmin) {
      window.location.href = '/admin/clients';
    } else if (cookies.token) {
      window.location.href = '/';
    } else {
      window.location.href = '/login';
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      position="relative"
    >
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '48px',
          fontWeight: 600,
          lineHeight: '58px',
          textAlign: 'center',
          color: '#000000',
          marginBottom: '47px',
        }}
      >
        {t('404 Page not Found')}
      </Typography>
      <img
        src={CustomIcon}
        alt="Custom Not Found Icon"
        style={{ width: 'auto', height: 'auto', marginBottom: '70px' }}
      />
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '24px',
          fontWeight: 400,
          lineHeight: '32px',
          textAlign: 'center',
          color: '#000000',
          marginBottom: '47px',
        }}
      >
        {t('You may need')}
      </Typography>

      <Button
        onClick={handleButtonClick}
        sx={{
          backgroundColor: '#E5E5E5',
          color: '#000',
          padding: '16px 24px',
          borderRadius: '16px',
          width: 'auto',
          fontSize: '18px',
          lineHeight: '28px',
          fontFamily: "'Open Sans', sans-serif",
          textAlign: 'center',
          textTransform: 'none',
          display: 'inline-block',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        {t('Go Back to Dashboard')}
      </Button>

      <img
        src={StripIcon}
        alt="Strip Icon"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default NotFound;

import styled from '@emotion/styled';
import { Button, Grid2, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronsLeftIcon } from '../../assets/icons/chevrons-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg';
import { ReactComponent as ChevronsRightIcon } from '../../assets/icons/chevrons-right.svg';
import { useTranslation } from 'react-i18next';

interface PaginationComponentProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const ButtonAction = styled(Button)`
  border-radius: 8px;
  min-width: 60px;
  width: 100%;
  min-height: 33px;
  max-height: 33px;
  border: 1px solid #0000001a;
  color: #000;

  &:hover {
    background-color: #eeeeee;
  }

  &.Mui-disabled {
    color: #000;
  }
`;

const Pagination: React.FC<PaginationComponentProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const [inputValue, setInputValue] = useState<string>(currentPage.toString());
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === '' || /^\d*$/.test(value)) {
      setInputValue(value);
    }
  };

  const applyPageChange = () => {
    let page = parseInt(inputValue, 10);
    if (isNaN(page) || page < 1) {
      page = 1;
    } else if (page > totalPages) {
      page = totalPages;
    }
    setInputValue(page.toString());
    onPageChange(page);
  };

  const handleInputBlur = () => {
    applyPageChange();
  };

  useEffect(() => {
    setInputValue(currentPage.toString());
  }, [currentPage]);

  return totalPages === 1 ? null : (
    <Grid2
      container
      display={'flex'}
      spacing={1}
      sx={{
        position: 'absolute',
        width: 'calc(100% - 140px)',
        bottom: 60,
      }}
    >
      <Grid2>
        <ButtonAction
          disabled={currentPage === 1}
          sx={{
            backgroundColor: '#fff',
          }}
          onClick={() => {
            onPageChange(1);
            setInputValue((1).toString());
          }}
        >
          <ChevronsLeftIcon
            style={{
              width: '18px',
              fill: currentPage === 1 ? '#e5e5e5' : '#000',
            }}
          />
        </ButtonAction>
      </Grid2>
      <Grid2>
        <ButtonAction
          disabled={currentPage === 1}
          sx={{
            backgroundColor: '#fff',
          }}
          onClick={() => {
            onPageChange(currentPage - 1);
            setInputValue((currentPage - 1).toString());
          }}
        >
          <ChevronLeftIcon
            style={{
              width: '12px',
              fill: currentPage === 1 ? '#e5e5e5' : '#000',
            }}
          />
        </ButtonAction>
      </Grid2>
      <Grid2 size={'grow'}>
        <ButtonAction
          disabled={totalPages === 1}
          sx={{
            backgroundColor:
              totalPages === 1
                ? '#FFF'
                : currentPage === 1
                ? '#B4EE2B'
                : '#FFF',
          }}
          onClick={() => {
            (() => {
              return totalPages > 6 && currentPage === 1
                ? onPageChange(currentPage)
                : totalPages > 6 && totalPages - currentPage < 4
                ? onPageChange(1)
                : totalPages > 6 && currentPage !== 1
                ? onPageChange(currentPage - 1)
                : onPageChange(1);
            })();
          }}
        >
          {totalPages > 6 && currentPage === 1
            ? currentPage
            : totalPages > 6 && totalPages - currentPage < 4
            ? 1
            : totalPages > 6 && currentPage !== 1
            ? currentPage - 1
            : 1}
        </ButtonAction>
      </Grid2>
      {totalPages > 1 && (
        <Grid2 size={'grow'}>
          <ButtonAction
            disabled={totalPages > 6 && totalPages - currentPage < 4}
            sx={{
              backgroundColor:
                currentPage === 2
                  ? '#B4EE2B'
                  : totalPages > 6 && totalPages - currentPage < 4
                  ? '#FFF'
                  : totalPages > 6 && currentPage !== 1
                  ? '#B4EE2B'
                  : '#FFF',
            }}
            onClick={() => {
              (() => {
                return totalPages > 6 && currentPage !== 1
                  ? onPageChange(currentPage)
                  : onPageChange(2);
              })();
            }}
          >
            {totalPages > 6 && totalPages - currentPage < 4
              ? '...'
              : totalPages > 6 && currentPage !== 1
              ? currentPage
              : 2}
          </ButtonAction>
        </Grid2>
      )}
      {totalPages > 2 && (
        <Grid2 size={'grow'}>
          <ButtonAction
            sx={{
              backgroundColor:
                currentPage === 3 && totalPages <= 6
                  ? '#B4EE2B'
                  : totalPages > 6 && totalPages - currentPage === 3
                  ? '#B4EE2B'
                  : '#FFF',
            }}
            onClick={() => {
              (() => {
                return totalPages > 6 && totalPages - currentPage < 4
                  ? onPageChange(totalPages - 3)
                  : totalPages > 6 && currentPage !== 1
                  ? onPageChange(currentPage + 1)
                  : onPageChange(3);
              })();
            }}
          >
            {totalPages > 6 && totalPages - currentPage < 4
              ? totalPages - 3
              : totalPages > 6 && currentPage !== 1
              ? currentPage + 1
              : 3}
          </ButtonAction>
        </Grid2>
      )}
      {totalPages > 3 && (
        <Grid2 size={'grow'}>
          <ButtonAction
            sx={{
              backgroundColor:
                currentPage === 4 && totalPages <= 6
                  ? '#B4EE2B'
                  : totalPages > 6 && totalPages - currentPage === 2
                  ? '#B4EE2B'
                  : '#FFF',
            }}
            onClick={() => {
              (() => {
                return totalPages > 6 && totalPages - currentPage < 4
                  ? onPageChange(totalPages - 2)
                  : totalPages > 6 && currentPage !== 1
                  ? onPageChange(currentPage + 2)
                  : onPageChange(4);
              })();
            }}
          >
            {totalPages > 6 && totalPages - currentPage < 4
              ? totalPages - 2
              : totalPages > 6 && currentPage !== 1
              ? currentPage + 2
              : 4}
          </ButtonAction>
        </Grid2>
      )}
      {totalPages > 4 && (
        <Grid2 size={'grow'}>
          <ButtonAction
            disabled={totalPages > 6 && totalPages - currentPage >= 4}
            sx={{
              backgroundColor:
                currentPage === 5 && totalPages <= 6
                  ? '#B4EE2B'
                  : totalPages > 6 && totalPages - currentPage === 1
                  ? '#B4EE2B'
                  : '#FFF',
            }}
            onClick={() => {
              (() =>
                totalPages > 6 && totalPages - currentPage < 4
                  ? onPageChange(totalPages - 1)
                  : onPageChange(5))();
            }}
          >
            {totalPages > 6 && totalPages - currentPage < 4
              ? totalPages - 1
              : totalPages > 6
              ? '...'
              : 5}
          </ButtonAction>
        </Grid2>
      )}
      {totalPages > 5 && (
        <Grid2 size={'grow'}>
          <ButtonAction
            sx={{
              backgroundColor:
                currentPage === 6 && totalPages <= 6
                  ? '#B4EE2B'
                  : totalPages > 6 && totalPages - currentPage === 0
                  ? '#B4EE2B'
                  : '#FFF',
            }}
            onClick={() => {
              (() =>
                totalPages > 6 ? onPageChange(totalPages) : onPageChange(6))();
            }}
          >
            {totalPages > 6 ? totalPages : 6}
          </ButtonAction>
        </Grid2>
      )}

      <Grid2>
        <ButtonAction
          disabled={currentPage === totalPages}
          sx={{
            backgroundColor: '#fff',
          }}
          onClick={() => {
            onPageChange(currentPage + 1);
            setInputValue((currentPage + 1).toString());
          }}
        >
          <ChevronRightIcon
            style={{
              width: '12px',
              fill: currentPage === totalPages ? '#e5e5e5' : '#000',
            }}
          />
        </ButtonAction>
      </Grid2>
      <Grid2>
        <ButtonAction
          disabled={currentPage === totalPages}
          sx={{
            backgroundColor: '#fff',
          }}
          onClick={() => {
            onPageChange(totalPages);
            setInputValue(totalPages.toString());
          }}
        >
          <ChevronsRightIcon
            style={{
              width: '18px',
              fill: currentPage === totalPages ? '#e5e5e5' : '#000',
            }}
          />
        </ButtonAction>
      </Grid2>

      <Grid2
        container
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography>{t('Page')}</Typography>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          sx={{
            'width': '30px',
            'height': '30px',

            '& .MuiInputBase-input': {
              padding: '4px 2px',
              textAlign: 'center',
            },
            '& .MuiOutlinedInput-root': {
              'borderRadius': '8px',
              'backgroundColor': '#EEEEEE',
              '& fieldset': {
                borderColor: 'transparent',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
        />
        <Typography>
          {t('of')} {totalPages}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default Pagination;

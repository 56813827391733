import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  ThemeProvider,
  Button,
  Box,
  CircularProgress,
} from '@mui/material';
import modalTheme from '../../../../theme/modalTheme';
import UploadIcon from '../../../../assets/icons/upload-icon';
import Papa from 'papaparse';
import { getDummyCsv } from '../../../../services/apiService';
import SnackbarComponent from '../../../../components/Snackbar/SnackbarComponent';
import { uploadSealCsv } from '../../../../services/apiService';
import { useTranslation } from 'react-i18next';

interface AddDevicesCSVProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

const AddDevicesCSV: React.FC<AddDevicesCSVProps> = ({
  open,
  onClose,
  onUploadSuccess,
}) => {
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [parsedData, setParsedData] = useState<unknown[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const { t } = useTranslation();

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      handleFile(files[0]);
    }
  };

  const handleFile = (selectedFile: File) => {
    if (
      selectedFile.type === 'text/csv' ||
      selectedFile.name.endsWith('.csv')
    ) {
      setFile(selectedFile);
      setError(null);
      parseCSV(selectedFile);
    } else {
      setError(t('Only CSV files are accepted'));
      setFile(null);
      setTimeout(() => setError(null), 1500);
    }
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    setDragging(false);
    const files = event.dataTransfer.files;
    if (files && files[0]) {
      const droppedFile = files[0];
      if (
        droppedFile.type === 'text/csv' ||
        droppedFile.name.endsWith('.csv')
      ) {
        setFile(droppedFile);
        setError(null);
        parseCSV(droppedFile);
      } else {
        setError(t('Only CSV files are accepted'));
        setFile(null);
        setTimeout(() => setError(null), 1500);
      }
    }
  };

  const handleUpload = async () => {
    if (parsedData.length > 0) {
      const formData = new FormData();
      formData.append('file', file as Blob);
      setLoading(true);
      try {
        const response = await uploadSealCsv(formData);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Seal_Upload_Response.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setSnackbarMessage(
          'File uploaded successfully, check the Download for details'
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        onUploadSuccess();
        forceClose();
      } catch (error) {
        console.error(t('Error Uploading CSV'), error);
        setSnackbarMessage('Error Uploading CSV');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const parseCSV = (file: File) => {
    setLoading(true);
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        const data = results.data;
        const errors = validateCSV(data);
        setTimeout(() => {
          if (errors.length > 0) {
            setValidationError(errors.join(', '));
          } else {
            setValidationError(null);
            setParsedData(data);
          }
          setLoading(false);
        }, 2000);
      },
      error: (error: { message: React.SetStateAction<string | null> }) => {
        setTimeout(() => {
          setValidationError(error.message);
          setLoading(false);
        }, 2000);
      },
    });
  };

  const validateCSV = (data: unknown[]): string[] => {
    const errors: string[] = [];

    data.forEach((row, index) => {
      if (typeof row === 'object' && row !== null && 'Seal Id' in row) {
        const sealId = (row as { 'Seal Id': string })['Seal Id'];
        if (!sealId) {
          errors.push(`Row ${index + 1}: Missing required field 'Seal Id'`);
        }
      } else {
        errors.push(`Row ${index + 1}: Invalid row format`);
      }
    });

    return errors;
  };

  const forceClose = () => {
    setFile(null);
    setParsedData([]);
    setValidationError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onClose();
  };

  const handleClose = () => {
    if (file) {
      setFile(null);
      setParsedData([]);
      setValidationError(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } else {
      onClose();
      setParsedData([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setValidationError(null);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await getDummyCsv();
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'example.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch {
      setSnackbarMessage('Error downloading example CSV');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Add Devices to system')}</DialogTitle>
        <Typography variant="subtitle1">
          {t('Upload a CSV to add new iMSeal devices to the system')}.{' '}
          <span
            onClick={handleDownload}
            style={{
              color: '#86BE02',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            role="button"
          >
            {'Download'}
          </span>{' '}
          {'example document'}.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Box
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              height: '168px',
              width: '400px',
              marginTop: '25px',
              padding: '24px',
              outline:
                error || validationError
                  ? '2px solid red'
                  : dragging
                  ? '2px solid #DEFF8F'
                  : '0px',
              cursor: 'pointer',
              backgroundColor: '#FBFBFB',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            {!error && !file && (
              <Box
                sx={{
                  height: '26px',
                  width: '26px',
                  backgroundColor: '#DEFF8F',
                  borderRadius: '16px',
                  padding: '16px',
                }}
              >
                <UploadIcon width={24} height={24} fill="#000000" />
              </Box>
            )}
            {error ? (
              <p
                style={{
                  color: 'red',
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                }}
              >
                {error}
              </p>
            ) : file ? (
              <>
                {parsedData.length === 0 && (
                  <p
                    style={{
                      fontSize: '18px',
                      fontFamily: 'Open Sans',
                      fontWeight: 700,
                    }}
                  >
                    {file.name}
                  </p>
                )}
                {loading && (
                  <CircularProgress sx={{ color: '#B4EE2B' }} size={40} />
                )}
                {parsedData.length > 0 && (
                  <div
                    style={{
                      maxHeight: '200px',
                      overflowY: 'auto',
                      marginTop: '10px',
                      width: '100%',
                    }}
                  >
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th style={{ padding: '8px', textAlign: 'left' }}>
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: 'Open Sans',
                                fontWeight: 700,
                              }}
                            >
                              {t('Seal ID')}
                            </Typography>
                          </th>
                          <th style={{ padding: '8px', textAlign: 'left' }}>
                            <Typography
                              variant="body1"
                              style={{
                                fontFamily: 'Open Sans',
                                fontWeight: 700,
                              }}
                            >
                              {t('Company Name')}
                            </Typography>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parsedData.map((row, index) => (
                          <tr key={index}>
                            <td style={{ padding: '8px', textAlign: 'left' }}>
                              <Typography
                                variant="body1"
                                style={{ fontFamily: 'Open Sans' }}
                              >
                                {(row as { 'Seal Id': string })['Seal Id']}
                              </Typography>
                            </td>
                            <td style={{ padding: '8px', textAlign: 'left' }}>
                              <Typography
                                variant="body1"
                                style={{ fontFamily: 'Open Sans' }}
                              >
                                {(row as { 'Company Name': string })[
                                  'Company Name'
                                ] || ' '}
                              </Typography>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div
                  style={{
                    maxHeight: '200px',
                    overflowY: 'auto',
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  {validationError && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        fontFamily: 'Open Sans',
                        marginTop: '10px',
                      }}
                    >
                      {validationError}
                    </p>
                  )}
                </div>
              </>
            ) : dragging ? (
              <p style={{ fontSize: '14px', fontFamily: 'Open Sans' }}>
                {t('Release to upload')}
              </p>
            ) : (
              <>
                <div
                  style={{
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    textAlign: 'center',
                    gap: '4px',
                  }}
                >
                  <p style={{ fontWeight: 700 }}>
                    {t('Drag and drop file to upload')}
                  </p>
                  <p style={{ fontWeight: 300 }}>
                    or,{' '}
                    <span
                      onClick={() => fileInputRef.current?.click()}
                      style={{
                        color: '#86BE02',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      role="button"
                    >
                      {t('Select File')}
                    </span>{' '}
                    {t('to browse')}.
                  </p>
                </div>
              </>
            )}
          </Box>
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!file || !parsedData.length || !!validationError}
            onClick={handleUpload}
          >
            {t('Upload')}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
};

export default AddDevicesCSV;

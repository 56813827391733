import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EditVehicleModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  formValues: {
    vehicle_registration: string;
    vehicle_alias: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      vehicle_registration: string;
      vehicle_alias: string;
    }>
  >;
}

const EditVehicleModal: React.FC<EditVehicleModalProps> = ({
  open,
  onClose,
  onSubmit,
  formValues,
  setFormValues,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({
        vehicle_registration: '',
        vehicle_alias: '',
      });
    }
  }, [open, setFormValues]);

  const { t } = useTranslation();

  const isFormValid = formValues.vehicle_registration.trim() !== '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Edit Vehicle')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'Changing the details below will edit the vehicles information. Assigning a iMSeal or driver can be done via the other actions for each vehicle.'
          )}
        </Typography>
        <DialogContent>
          <Typography variant="subtitle2">
            {t('Vehicle Registration')}*
          </Typography>
          <TextField
            placeholder={t('Vehicle Registration')}
            type="text"
            value={formValues.vehicle_registration}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                vehicle_registration: e.target.value,
              })
            }
            fullWidth
          />
          <Typography variant="subtitle2">{t('Vehicle Alias')}</Typography>
          <TextField
            placeholder={t('Vehicle Alias')}
            type="text"
            value={formValues.vehicle_alias}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                vehicle_alias: e.target.value,
              })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {t('Edit Vehicle')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditVehicleModal;

let driver_id = '';

export const getDriverId = () => {
  return driver_id;
};

export const setDriverId = (newDriverId: string) => {
  driver_id = newDriverId;
  window.dispatchEvent(new Event('openDriverCard'));
};

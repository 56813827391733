import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';

interface ConfirmSaveModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmSaveModal: React.FC<ConfirmSaveModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm Changing Settings</DialogTitle>
        <Typography variant="subtitle1">
          In order for the changed settings to be affective, yourself and all of
          the other users from your company will be immediately logged out and
          will need to log in again. Are you sure you want to save the changes?
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} color="primary">
            Change Settings
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ConfirmSaveModal;

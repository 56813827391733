import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import modalTheme from '../../../theme/modalTheme';
import { ThemeProvider } from '@mui/material/styles';

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  inputRef: React.RefObject<HTMLInputElement>;
}

const GenericAutocomplete: React.FC<Props> = ({ onPlaceSelect, inputRef }) => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const places = useMapsLibrary('places');
  const debounceTimeout = useRef<number | null>(null);

  useEffect(() => {
    if (!places || !inputRef.current) {
      return;
    }
  }, [places, inputRef]);

  const handleInputChange = (
    event: React.ChangeEvent<unknown>,
    value: string
  ) => {
    setInputValue(value);
    onPlaceSelect(null);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = window.setTimeout(() => {
      if (places) {
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input: value }, (predictions) => {
          if (predictions) {
            setOptions(predictions);
          }
        });
      }
    }, 1000);
  };

  const handleOptionSelect = (
    event: React.ChangeEvent<unknown>,
    value: string | null
  ) => {
    if (value && places) {
      const service = new google.maps.places.PlacesService(
        document.createElement('div')
      );
      service.getDetails(
        {
          placeId:
            options.find((option) => option.description === value)?.place_id ||
            '',
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            onPlaceSelect(place);
          } else {
            onPlaceSelect(null);
          }
        }
      );
    } else {
      onPlaceSelect(null);
    }
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Autocomplete
        freeSolo
        options={options.map((option) => option.description)}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleOptionSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            placeholder="Search places"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-input': {
                'padding': '10px 14px',
                'height': '0.8em',
                '&::placeholder': {
                  color: '#C7C7C7',
                },
              },
            }}
          />
        )}
      />
    </ThemeProvider>
  );
};

export default GenericAutocomplete;

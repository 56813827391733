import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  Typography,
  Checkbox,
  Grid2,
} from '@mui/material';
import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import { useTranslation } from 'react-i18next';

interface SkipAddressModalProps {
  open: boolean;
  onClose: () => void;
  formValues: { reAddAddress: boolean };
  setFormValues: React.Dispatch<
    React.SetStateAction<{ reAddAddress: boolean }>
  >;
  onSubmit: () => void;
}

const SkipAddressModal: React.FC<SkipAddressModalProps> = ({
  open,
  onClose,
  formValues,
  setFormValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Skip Address')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'Skipping a route item will Strike it from the Table and the remove it from Driver’s route. Tick the box below to re-add the address at the end of the Driver’s route.'
          )}
        </Typography>
        <DialogContent
          onClick={() =>
            setFormValues({ reAddAddress: !formValues.reAddAddress })
          }
        >
          <Grid2
            container
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Grid2>
              <Checkbox
                checked={formValues.reAddAddress}
                onChange={() =>
                  setFormValues({ reAddAddress: !formValues.reAddAddress })
                }
                sx={{
                  'padding': '0',
                  'marginTop': '1px',
                  '& .MuiSvgIcon-root': {
                    width: '20px',
                    height: '20px',
                  },
                  '&.Mui-checked': {
                    color: '#000',
                  },
                }}
              />
            </Grid2>
            <Grid2
              sx={{
                marginLeft: '10px',
              }}
            >
              <Typography
                variant="subtitle2"
                onClick={() =>
                  setFormValues({ reAddAddress: !formValues.reAddAddress })
                }
                sx={{
                  fontSize: '14px',
                }}
              >
                {t('Re-add address at end of list')}
              </Typography>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={onSubmit} color="primary">
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default SkipAddressModal;

import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  strength: 0 | 1 | 2 | 3 | 4;
}

const ShowPasswordStrength = ({ strength }: Props) => {
  const strengthColors = ['#DD3333', '#FF9500', '#FFCC00', '#B4EE2B'];

  const indicatorColors = ['#E5E5E5', '#E5E5E5', '#E5E5E5', '#E5E5E5'];

  if (strength >= 1) indicatorColors[0] = strengthColors[0];
  if (strength >= 2) indicatorColors[1] = strengthColors[1];
  if (strength >= 3) indicatorColors[2] = strengthColors[2];
  if (strength === 4) indicatorColors[3] = strengthColors[3];

  const message =
    strength === 0
      ? 'Use a minimum of 8 characters with a mix of letters, numbers, and symbols.'
      : strength === 1
      ? 'Weak password. Try adding more characters or symbols.'
      : strength === 2
      ? 'Okay password! Consider improving it further.'
      : strength === 3
      ? 'Good password! You are almost there.'
      : 'Strong password!';

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          marginBottom: '10px',
        }}
      >
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            style={{
              height: '4px',
              width: '75px',
              borderRadius: '10px',
              backgroundColor: indicatorColors[index],
            }}
          ></div>
        ))}
      </div>
      <Typography
        variant="caption"
        sx={{
          fontFamily: 'Open Sans',
          fontWeight: 400,
          lineHeight: '18px',
          letterSpacing: '0px',
          color: '#838383',
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        {message}
      </Typography>
    </div>
  );
};

export default ShowPasswordStrength;

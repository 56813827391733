import React from 'react';
import { Box, Typography, Button, Grid2 } from '@mui/material';
import { ReactComponent as EmergencyIcon } from '../../assets/icons/emergency.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { setLocation } from '../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { setDriverId } from '../../utils/expandDriverCard';
import { useTranslation } from 'react-i18next';

interface EmergencyModalProps {
  message: string;
  onClose: () => void;
  details: {
    contact_number: string;
    vehicle_alias: string;
    driver_id: string;
    driver_name: string;
    latitude: string;
    longitude: string;
  };
}

const EmergencyModal: React.FC<EmergencyModalProps> = ({
  message,
  onClose,
  details,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleAction = () => {
    setLocation(parseFloat(details.latitude), parseFloat(details.longitude));
    navigate('/');
    setDriverId(details.driver_id);
    window.dispatchEvent(new Event('openDriverCard'));
  };
  return (
    <Box
      sx={{
        width: 250,
        padding: 3,
        background: '#BA1F1F',
        boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.05)',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        position: 'relative',
      }}
    >
      <Grid2 container direction="column" spacing={2}>
        <Grid2
          container
          direction="row"
          spacing={2}
          sx={{ width: '100%', height: 'auto', alignItems: 'center' }}
        >
          <Grid2
            sx={{
              width: 56,
              height: 56,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                width: 56,
                height: 56,
                background: 'rgba(255, 255, 255, 0.10)',
                borderRadius: 4,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'inline-flex',
              }}
            >
              <EmergencyIcon
                style={{
                  width: 24,
                  height: 24,
                  textAlign: 'center',
                  color: 'white',
                }}
              />
            </Button>
          </Grid2>
          <Grid2 sx={{ flexGrow: 3, minWidth: '0', paddingLeft: 0 }}>
            <Grid2 container direction="column" spacing={0}>
              <Grid2>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white',
                    fontSize: 18,
                    lineHeight: '20px',
                  }}
                >
                  {details.driver_name}
                </Typography>
              </Grid2>
              <Grid2>
                <Typography
                  variant="body1"
                  sx={{ color: 'white', fontSize: 12, lineHeight: '18px' }}
                >
                  {details.vehicle_alias}
                </Typography>
              </Grid2>
              <Grid2
                container
                direction="row"
                spacing={0.5}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PhoneIcon
                  style={{
                    width: 10,
                    height: 14,
                    fill: 'white',
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{ color: 'white', fontSize: 12, lineHeight: '18px' }}
                >
                  {details.contact_number}
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>
          <button
            onClick={onClose}
            style={{
              width: 12,
              height: 12,
              padding: 0,
              margin: 0,
              border: 'none',
              background: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              position: 'absolute',
              top: 14,
              right: 14,
            }}
          >
            <CloseIcon
              style={{ width: '100%', height: '100%', cursor: 'pointer' }}
            />
          </button>
        </Grid2>

        <Grid2 sx={{ width: '100%', textAlign: 'center' }}>
          <Typography
            variant="h6"
            sx={{ color: 'white', wordWrap: 'break-word' }}
          >
            {message}
          </Typography>
        </Grid2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAction}
          sx={{
            width: '100%',
            height: 25,
            padding: '8px 16px',
            background: 'rgba(255, 255, 255, 0.20)',
            borderRadius: 2,
            color: 'white',
            fontSize: 12,
            fontWeight: 400,
            justifyContent: 'center',
            textTransform: 'none',
            boxShadow: 'none',
          }}
        >
          {t('View Last Known Location')}
        </Button>
      </Grid2>
    </Box>
  );
};

export default EmergencyModal;

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ReactComponent as ReturnIcon } from '../../assets/icons/rotate-right.svg';
import { superUserLogoutOfClient } from '../../services/userService';
import SnackbarComponent from '../Snackbar/SnackbarComponent';

const ClientAccessIndicator = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['user']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const handleReturnClick = async () => {
    try {
      const response = await superUserLogoutOfClient({
        email: cookies.user.email,
        company_id: cookies.user.company_id,
      });

      if (response.statusCode === 200) {
        setCookie('user', { ...cookies.user, company_id: '' }, { path: '/' });
        navigate('/admin/clients');
      } else {
        setSnackbarMessage('Failed to log return to admin console');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch {
      setSnackbarMessage('Failed to log return to admin console');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          'position': 'fixed',
          'top': 25,
          'left': '50%',
          'height': '34px',
          'width': '228px',
          'transform': 'translateX(-50%)',
          'backgroundColor': '#000000',
          'color': '#ffffff',
          'boxShadow': 'none',
          'textTransform': 'none',
          'fontWeight': 300,
          'fontFamily': 'Open Sans',
          'borderRadius': '12px',
          'fontSize': '15px',
          'lineHeight': '28px',
          'zIndex': 1500,
          '&:hover': {
            backgroundColor: '#333333',
          },
          '&.Mui-disabled': {
            backgroundColor: '#EEEEEE',
            color: '#C7C7C7',
          },
        }}
        onClick={handleReturnClick}
      >
        Return to Admin Console <ReturnIcon style={{ marginLeft: '8px' }} />
      </Button>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </>
  );
};
export default ClientAccessIndicator;

import React from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import StripIcon from '../../assets/icons/not-found-strip.svg';
import gritLogo from '../../assets/icons/grit-logo.svg';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      flexDirection="column"
      position="relative"
    >
      <img
        src={gritLogo}
        alt="grit logo"
        style={{
          height: '10vh',
          width: '100%',
          maxWidth: '30vw',
        }}
      />
      <Typography
        sx={{
          fontFamily: 'Open Sans',
          fontSize: '3vw',
          fontWeight: 600,
          width: '100%',
          maxWidth: '50vw',
          textAlign: 'center',
          color: '#000000',
          marginBottom: '2vh',
        }}
      >
        {t('Privacy Policy')}
      </Typography>
      <Grid2
        container
        sx={{
          display: 'flex',
          width: '80%',
          height: '60vh',
          overflow: 'auto',
        }}
      >
        <Typography variant="body1" sx={{ fontSize: '2vw', height: '100%' }}>
          <p>
            <strong>{t('Privacy Policy for GritCargo')}</strong>
          </p>
          <p>
            <strong>{t('Effective Date: 2024-09-01')}</strong>
          </p>
          <p>
            <strong>{t('Version: 1')}</strong>
          </p>
          <p>
            <strong>{t('1. Introduction')}</strong>
          </p>
          <p>
            {t(
              'Welcome to GritCargo ("we", "our", "us"). GritCargo provides mobile application services (the "App"). We are committed to protecting your privacy and ensuring your personal information is handled securely and responsibly. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our App. It also describes your rights in relation to your personal information and how to exercise them. By downloading, installing, or using the App, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please uninstall and discontinue using the App.'
            )}
          </p>
          <p>
            <strong>{t('2. Information We Collect')}</strong>
          </p>
          <p>
            {t(
              'We collect different types of information from or about you depending on how you interact with our App.'
            )}
          </p>
          <ol>
            <li>
              <strong>{t('Information You Provide to Us')}</strong>
              <ul>
                <li>
                  {t(
                    'Personal Information: When you use our App, we may collect personal information that you voluntarily provide, such as: Name, Email address, Phone number, Profile picture (if applicable), Payment and billing information (if applicable), Employer or contractor (if applicable).'
                  )}
                </li>
                <li>
                  {t(
                    'Account Information: If you create an account within the App, we may collect your username, password, and profile details.'
                  )}
                </li>
                <li>
                  {t(
                    'Communication Data: When you contact us via email, chat, or other communication channels, we may collect and store those communications.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('Information Collected Automatically')}</strong>
              <ul>
                <li>
                  {t(
                    'Usage Data: We may automatically collect certain information about your use of the App, including: Device model and manufacturer, Operating system version, Unique device identifiers (e.g., Android ID, advertising ID), IP address, App usage statistics (e.g., features used, session duration), Crash data and performance diagnostics, Location data.'
                  )}
                </li>
                <li>
                  {t(
                    'Cookies and Tracking Technologies: We may use cookies, pixel tags, and similar technologies to track your activity within the App.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('Information from Third-Party Sources')}</strong>
              <ul>
                <li>
                  {t(
                    'We may receive information about you from third parties, such as: Social media platforms (if you log in via Facebook, Google, etc.), Analytics providers (Google Analytics, Firebase, etc.), Advertising networks (if you interact with in-app advertisements).'
                  )}
                </li>
              </ul>
            </li>
          </ol>
          <span>
            <strong>{t('3. How We Use Your Information')}</strong>
          </span>
          <p>
            {t(
              'We use the information we collect from you in the following ways:'
            )}
          </p>
          <ol>
            <li>
              <strong>{t('To Provide and Improve Our Services')}</strong>
              <ul>
                <li>{t('Facilitate and manage your access to the App.')}</li>
                <li>
                  {t(
                    'Personalize your experience by providing custom content, recommendations, and services.'
                  )}
                </li>
                <li>
                  {t('Ensure the security and integrity of our systems.')}
                </li>
                <li>
                  {t('Improve the functionality and performance of the App.')}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('To Communicate with You')}</strong>
              <ul>
                <li>
                  {t('Respond to your inquiries and provide customer support.')}
                </li>
                <li>
                  {t(
                    'Send you updates, notices, and information about the App (e.g., newsletters, promotions, or administrative messages).'
                  )}
                </li>
                <li>
                  {t(
                    'Notify you of any changes to the App, our services, or policies.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('For Legal and Security Purposes')}</strong>
              <ul>
                <li>
                  {t(
                    'Detect, investigate, and prevent fraud, abuse, or other unlawful activities.'
                  )}
                </li>
                <li>{t('Enforce our terms and conditions.')}</li>
                <li>
                  {t(
                    'Comply with applicable legal requirements, regulations, or requests from law enforcement.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('For Marketing Purposes')}</strong>
              <ul>
                <li>
                  {t(
                    'With your consent, we may use your information to show personalized advertisements and offers.'
                  )}
                </li>
                <li>
                  {t(
                    'We may also send you promotional materials for products or services that we believe may interest you.'
                  )}
                </li>
              </ul>
            </li>
          </ol>
          <p>
            <strong>{t('4. How We Share Your Information')}</strong>
          </p>
          <p>
            {t(
              'We do not sell or rent your personal information. However, we may share your information with third parties under the following circumstances:'
            )}
          </p>
          <ol>
            <li>
              <strong>{t('Service Providers')}</strong>
              <ul>
                <li>
                  {t(
                    'We may share your information with trusted third-party service providers that perform services on our behalf, such as: Cloud storage and hosting providers, Payment processors, Data analytics and crash reporting services (e.g., Firebase, Google Analytics), Marketing and advertising partners.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('Business Transfers')}</strong>
              <ul>
                <li>
                  {t(
                    'If we undergo a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('Legal Requirements')}</strong>
              <ul>
                <li>
                  {t(
                    'We may disclose your information if required to do so by law or in response to valid legal processes (e.g., subpoenas, court orders).'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('With Your Consent')}</strong>
              <ul>
                <li>
                  {t(
                    'We may share your information with third parties if you provide explicit consent for such sharing.'
                  )}
                </li>
              </ul>
            </li>
          </ol>
          <p>
            <strong>{t('5. Data Retention')}</strong>
          </p>
          <span>
            {t(
              "We will retain your personal information for as long as necessary to provide you with the App's services, comply with our legal obligations, resolve disputes, and enforce our agreements. When we no longer need to retain your information, we will securely delete or anonymize it."
            )}
          </span>
          <p>
            <strong>{t('6. Your Privacy Rights')}</strong>
          </p>
          <ol>
            <li>
              <strong>{t('European Union (GDPR)')}</strong>
              <ul>
                <li>
                  {t(
                    'If you are located in the European Union, you have certain rights under the General Data Protection Regulation (GDPR), including: The right to access, correct, or delete your personal information, The right to restrict or object to the processing of your data, The right to data portability, The right to withdraw your consent to data processing at any time. To exercise any of these rights, please contact us at accounts@gritcargo.com.'
                  )}
                </li>
              </ul>
            </li>
            <li>
              <strong>{t('California (CCPA)')}</strong>
              <ul>
                <li>
                  {t(
                    "If you are a California resident, you have specific rights under the California Consumer Privacy Act (CCPA), including: The right to know what personal information we collect and how it's used, The right to request deletion of your personal information, The right to opt-out of the sale of your personal information (though we do not sell personal information). To exercise your rights under CCPA, please contact us at accounts@gritcargo.com]."
                  )}
                </li>
              </ul>
            </li>
          </ol>
          <p>
            <strong>{t('7. Security')}</strong>
          </p>
          <p>
            {t(
              'We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no security measure is entirely foolproof, and we cannot guarantee the absolute security of your information.'
            )}
          </p>
          <p>
            <strong>{t("8. Children's Privacy")}</strong>
          </p>
          <p>
            {t(
              'Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us, and we will promptly delete it.'
            )}
          </p>
          <p>
            <strong>{t('9. International Data Transfers')}</strong>
          </p>
          <p>
            {t(
              'We may transfer and store your personal information in countries outside of your home jurisdiction, including countries that may not offer the same level of data protection as your home country. By using the App, you consent to the transfer of your information to these countries.'
            )}
          </p>
          <p>
            <strong>{t('10. Changes to This Privacy Policy')}</strong>
          </p>
          <p>
            {t(
              'We may update this Privacy Policy from time to time. When we make significant changes, we will notify you by updating the "Effective Date" at the top of this document and, where appropriate, provide additional notice (such as in-app notifications). We encourage you to review this Privacy Policy periodically to stay informed about our privacy practices.'
            )}
          </p>
          <p>
            <strong>{t('11. Contact Us')}</strong>
          </p>
          <p>
            {t(
              'If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at: GritCargo HoldCo Pty Ltd, Email: accounts@gritcargo.com, Address: 25 Shoal Creek, Paal, South Africa, 7646.'
            )}
          </p>
        </Typography>
      </Grid2>
      <img
        src={StripIcon}
        alt="Strip Icon"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default PrivacyPolicy;

import React, { useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  ThemeProvider,
  Typography,
} from '@mui/material';
import modalTheme from '../../theme/modalTheme';
import { useTranslation } from 'react-i18next';

interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type: string;
  name: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  type,
  name,
}) => {
  const { t } = useTranslation();

  const driverMessage = `${t('You are about to delete')} ${name}’s account. ${t(
    'This action is permanent and cannot be undone.'
  )}  ${t('The Driver’s history logs will remain on the system.')}`;
  const clientMessage = `${t('You are about to delete')} ${name}’s account. ${t(
    'This action is permanent and cannot be undone.'
  )}`;
  const operatorMessage = `${t(
    'You are about to delete'
  )} ${name}’s account. ${t(
    'This action is permanent and cannot be undone.'
  )}.`;
  const vehicleMessage = `${t('You are about to delete')} ${name}. ${t(
    'This action is permanent and cannot be undone.'
  )} ${t('The vehicle’s history logs will remain on the system.')}`;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onConfirm();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onConfirm, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {t('Delete')} {t(type)}
        </DialogTitle>
        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '16px',
            fontWeight: 400,
            padding: '4px 26px 26px 26px',
          }}
        >
          {type === 'Driver'
            ? driverMessage
            : type === 'Client'
            ? clientMessage
            : type === 'Operator'
            ? operatorMessage
            : type === 'Vehicle'
            ? vehicleMessage
            : `${t('You are about to delete')} ${name}. ${t(
                'This action is permanent and cannot be undone.'
              )}`}
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={onConfirm} variant="contained" color="primary">
            {t('Delete')} {type}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DeleteModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const PrivateRoute = ({ element: Component, adminOnly = false }) => {
  const [cookies] = useCookies(['user', 'super_user', 'token']);

  if (!cookies.token) {
    return <Navigate to="/login" />;
  }

  if (!cookies.user) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && !cookies.super_user) {
    return <Navigate to="/login" />;
  }

  return <Component />;
};

PrivateRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
  adminOnly: PropTypes.bool,
};

export default PrivateRoute;

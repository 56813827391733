import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import { useTranslation } from 'react-i18next';

interface CancelAddressModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const CancelAddressTripModal: React.FC<CancelAddressModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Cancel Address')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            "Cancelling a route item will Strike it from the Table and the remove it from Driver's route."
          )}
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={onSubmit} color="primary">
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CancelAddressTripModal;

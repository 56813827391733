import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import LaptopIcon from '../../../assets/icons/laptop.svg';
import { useNavigate } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { validateEmail } from '../../../utils/validationUtils';
import { sendOtpToEmail, verifyOtp } from '../../../services/userService';
import { useTranslation } from 'react-i18next';

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState('');
  const { t } = useTranslation();

  const isFormValid = otp.length === 6;
  const isEmailFormValid = !emailError && email !== '';

  const handleNavigateToSignIn = () => {
    navigate('/login');
  };

  const handleNavigateToCreatePassword = async () => {
    if (!isFormValid) return;

    setLoading(true);

    const response = await verifyOtp(email, otp);

    if (response.success) {
      const token = response.data?.tempOtpToken;
      navigate('/reset-password', { state: { email, token } });
    } else {
      setOtpError(response.message || 'OTP verification failed');
    }

    setLoading(false);
  };

  const handleResetPasswordClick = async () => {
    if (!isEmailFormValid) return;

    setLoading(true);
    console.log('Sending OTP to:', email);

    const response = await sendOtpToEmail(email);
    console.log('Response:', response);

    if (response.success) {
      console.log('OTP sent successfully:', response.message);
      setShowOtpField(true);
      setOtpError('');
    } else {
      console.error('Error sending OTP:', response.message);
      setOtpError(response.message);
    }
    setLoading(false);
  };

  const handleResendOtpClick = async () => {
    if (!isEmailFormValid) return;

    setLoading(true);
    setOtpError('');

    console.log('Resending OTP to:', email);
    const response = await sendOtpToEmail(email);

    if (response.success) {
      console.log('OTP resent successfully:', response.message);
    } else {
      console.error('Error resending OTP:', response.message);
      setOtpError(response.message);
    }

    setLoading(false);
  };

  const handleOtpChange = (newValue: string) => {
    setOtp(newValue);
    setOtpError('');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!validateEmail(newEmail));
    setOtpError('');
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'relative',
          width: 'auto',
          minWidth: '30vw',
          maxWidth: '30vw',
          padding: '50.7px 70.98px 70.98px 70.98px',
          borderRadius: '16px',
          backgroundColor: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          overflow: 'hidden',
          border: '2px solid #B4EE2B',
        }}
      >
        <img
          src={LaptopIcon}
          alt="Laptop Not Found Icon"
          style={{
            width: '100px',
            height: '100px',
            marginTop: '40.56px',
          }}
        />

        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '32px',
            fontWeight: 600,
            lineHeight: '40px',
            textAlign: 'center',
            color: '#000000',
            marginTop: '45px',
          }}
        >
          {t('Forgot Password')}
        </Typography>

        <Typography
          sx={{
            fontFamily: 'Open Sans',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'center',
            color: '#838383',
            marginTop: '6px',
          }}
        >
          {t(
            'An OTP to reset your password will be sent to your email address'
          )}
          .
        </Typography>

        {!showOtpField && (
          <>
            <TextField
              placeholder={t('Email')}
              fullWidth
              value={email}
              onChange={handleEmailChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleResetPasswordClick();
                }
              }}
              sx={{
                'backgroundColor': '#FFFFFF',
                'borderRadius': '8px',
                'marginTop': '61px',
                '& .MuiOutlinedInput-root': {
                  'borderColor': '#C7C7C7',
                  '& fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C7C7C7',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '14px 14px',
                  color: '#000',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
                '& .MuiInputLabel-root': {
                  color: '#C7C7C7',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#C7C7C7',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#C7C7C7',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
              }}
            />
            <Typography
              sx={{
                color:
                  emailError || otpError === t('Email not found in the system')
                    ? 'red'
                    : 'transparent',
                fontSize: '14px',
                marginRight: 'auto',
                visibility:
                  emailError || otpError === t('Email not found in the system')
                    ? 'visible'
                    : 'hidden',
              }}
            >
              {emailError
                ? t('Must be a valid email')
                : otpError === t('Email not found in the system')
                ? t('Email not found in the system')
                : 'Placeholder'}
            </Typography>
          </>
        )}

        {showOtpField && (
          <>
            <Typography
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: '#000000',
                marginTop: '61px',
              }}
            >
              Enter 6-digit OTP
            </Typography>

            <MuiOtpInput
              value={otp}
              length={6}
              onChange={handleOtpChange}
              sx={{
                'marginTop': '20px',
                '& .MuiOutlinedInput-root': {
                  'borderRadius': '8.11px',
                  'border': `1.01px solid ${otpError ? 'red' : '#C7C7C7'}`,
                  'width': '45px',
                  'height': '44.62px',
                  '& fieldset': {
                    display: 'none',
                  },
                  '&.Mui-focused': {
                    borderColor: otpError ? 'red' : '#B4EE2B',
                  },
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'center',
                  paddingTop: '10.14px',
                  paddingRight: '14.2px',
                  paddingBottom: '10.14px',
                  paddingLeft: '14.2px',
                },
              }}
            />
          </>
        )}

        <Typography
          sx={{
            color:
              otpError && otpError !== t('Email not found in the system')
                ? 'red'
                : 'transparent',
            fontSize: '14px',
            marginTop: '40px',
            textAlign: 'center',
          }}
        >
          {otpError && otpError !== t('Email not found in the system')
            ? otpError
            : 'PLACEHOLDER'}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0px 0px 24px',
            width: '100%',
            marginTop: '40px',
          }}
        >
          {!showOtpField ? (
            <Button
              sx={{
                backgroundColor: isEmailFormValid ? '#000000' : '#EEEEEE',
                color: isEmailFormValid ? '#FFFFFF' : '#838383',
                padding: '16px 24px',
                borderRadius: '16px',
                width: '100%',
                fontSize: '18px',
                lineHeight: '28px',
                fontFamily: "'Open Sans', sans-serif",
                textAlign: 'center',
                textTransform: 'none',
                display: 'inline-block',
                position: 'relative',
                cursor: isEmailFormValid ? 'pointer' : 'not-allowed',
              }}
              disabled={!isEmailFormValid}
              onClick={handleResetPasswordClick}
            >
              {t('Send Email')}
            </Button>
          ) : (
            <Button
              sx={{
                backgroundColor: isFormValid ? '#000000' : '#EEEEEE',
                color: isFormValid ? '#FFFFFF' : '#838383',
                padding: '16px 24px',
                borderRadius: '16px',
                width: '100%',
                fontSize: '18px',
                lineHeight: '28px',
                fontFamily: "'Open Sans', sans-serif",
                textAlign: 'center',
                textTransform: 'none',
                display: 'inline-block',
                position: 'relative',
                cursor: isFormValid ? 'pointer' : 'not-allowed',
              }}
              onClick={handleNavigateToCreatePassword}
              disabled={!isFormValid}
            >
              {t('Confirm OTP')}
            </Button>
          )}
        </Box>

        {!showOtpField ? (
          <Typography
            sx={{
              fontSize: '14px',
              color: '#000000',
              textAlign: 'center',
              lineHeight: '20px',
              fontFamily: 'Open Sans',
              fontWeight: 600,
            }}
          >
            {t('Go back to')}{' '}
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                color: '#86BE02',
                cursor: 'pointer',
                fontFamily: 'Open Sans',
                fontWeight: 600,
                lineHeight: '20px',
              }}
              onClick={handleNavigateToSignIn}
            >
              {t('Sign In')}
            </Typography>
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: '14px',
              color: '#000000',
              textAlign: 'center',
              lineHeight: '20px',
              fontFamily: 'Open Sans',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={handleResendOtpClick}
          >
            {t('Resend OTP')}
          </Typography>
        )}
      </Box>

      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{ color: '#B4EE2B' }} size={80} />
        </Box>
      )}
    </Box>
  );
};

export default ForgotPasswordComponent;

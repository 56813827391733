import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PhoneInput from 'react-phone-input-2';

const StyledPhoneInput = styled(PhoneInput)(
  () => css`
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    & .form-control {
      width: 100%;
      height: 44px;
      border-radius: 8px;
      border-color: rgba(0, 0, 0, 0.23);
      font-size: 16px;
    }
    & .flag-dropdown {
      border-radius: 8px 0 0 8px;
    }
    &:hover {
      & .form-control {
        border-color: #000000;
        border-width: 1px;
      }
      & .flag-dropdown {
        border-color: #000000;
        border-width: 1px;
        border-radius: 8px 0 0 8px;
      }
      & .selected-flag {
        border-radius: 8px 0 0 8px;
      }
    }
    &:focus-within {
      & .form-control {
        border-color: #000000;
        border-width: 2px;
      }
      & .flag-dropdown {
        border-color: #000000;
        border-width: 2px;
        border-radius: 8px 0 0 8px;
      }
      & .selected-flag {
        border-radius: 8px 0 0 8px;
      }
    }
  `
);

export default StyledPhoneInput;

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid2,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ReactComponent as LogoutIcon } from '../../assets/icons/arrow-right-from-bracket.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Logout } from '../../services/userService';
import { useTranslation } from 'react-i18next';

const AdminNavbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState<string>(location.pathname);
  const cookies = new Cookies();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const drawerWidth = '90px';
  const iconWidth = '30px';

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogoutConfirm = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await Logout({
          email: cookies.get('user').email,
          token: cookies.get('token'),
        });
      } catch {
        console.error('Failed to delete token');
      }
      setLoading(false);
      cookies.remove('user', { path: '/' });
      cookies.remove('super_user', { path: '/' });
      cookies.remove('token', { path: '/' });
      cookies.remove('role', { path: '/' });
      cookies.remove('settings', { path: '/' });
      navigate('/login');
      setDrawerOpen(false);
    }, 1000);
  };

  useEffect(() => {
    setActivePage(location.pathname);
  }, [location]);

  const icons = [
    {
      onClick: () => {
        navigate('/admin/clients');
      },
      page: '/admin/clients',
    },
    {
      onClick: () => {
        navigate('/admin/seals');
      },
      page: '/admin/seals',
    },
  ];

  return (
    <Box
      display={'flex'}
      sx={{
        width: drawerWidth,
        borderRight: '1px solid #EEEEEE',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid2
        container
        display={'flex'}
        direction={'column'}
        alignItems={'center'}
        sx={{
          width: drawerWidth,
          height: '100%',
          marginTop: '10px',
        }}
      >
        <Grid2 display={'flex'} alignItems={'center'}>
          <List
            sx={{
              '& .MuiListItem-root': { marginBottom: '20px' },
            }}
          >
            {icons.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  setActivePage(item.page);
                  item.onClick();
                }}
                sx={{
                  backgroundColor:
                    activePage === item.page ? '#B4EE2B' : 'transparent',
                  borderRadius: '16px',
                  padding: '13px',
                  boxShadow:
                    activePage === item.page
                      ? '0px 4px 6px rgba(0, 0, 0, 0.1)'
                      : 'none',
                }}
              >
                {item.page === '/admin/clients' ? (
                  <UsersIcon
                    style={{
                      width: iconWidth,
                      height: iconWidth,
                      cursor: 'pointer',
                      fill: '#2C3741',
                    }}
                  />
                ) : item.page === '/admin/seals' ? (
                  <LockIcon
                    style={{
                      width: iconWidth,
                      height: iconWidth,
                      cursor: 'pointer',
                      fill: '#2C3741',
                    }}
                  />
                ) : null}
              </ListItem>
            ))}
          </List>
        </Grid2>
        <Grid2 flexGrow={1}></Grid2>
        <Grid2>
          <List
            sx={{
              '& .MuiListItem-root': { marginBottom: '20px' },
            }}
          >
            <ListItem
              key={'logout'}
              onClick={handleDrawerOpen}
              sx={{
                borderRadius: '16px',
                padding: '13px',
              }}
            >
              <LogoutIcon
                style={{
                  width: iconWidth,
                  height: iconWidth,
                  cursor: 'pointer',
                  fill: '#2C3741',
                }}
              />
            </ListItem>
          </List>
        </Grid2>
      </Grid2>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            height: '180px',
            width: '250px',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            overflowX: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '90px',
          },
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontFamily: 'Open Sans',
              color: '#101828',
              fontSize: '1rem',
              fontWeight: 600,
              marginBottom: '20px',
            }}
          >
            {loading
              ? t('Logging out...')
              : t('Are you sure you want to logout?')}
          </Typography>
          {loading ? (
            <CircularProgress sx={{ color: '#B4EE2B' }} size={40} />
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDrawerClose}
                sx={{
                  textTransform: 'none',
                  fontWeight: 400,
                  fontFamily: 'Open Sans',
                  borderRadius: '12px',
                  padding: '6px 16px',
                  borderColor: '#E5E5E5',
                  color: '#000000',
                  margin: '8px',
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogoutConfirm}
                sx={{
                  textTransform: 'none',
                  fontWeight: 400,
                  fontFamily: 'Open Sans',
                  borderRadius: '12px',
                  padding: '6px 16px',
                  backgroundColor: '#000',
                  color: '#fff',
                  boxShadow: 'none',
                  margin: '8px',
                }}
              >
                {t('Confirm')}
              </Button>
            </>
          )}
        </div>
      </Drawer>
    </Box>
  );
};

export default AdminNavbar;

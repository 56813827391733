import axiosInstance from '../utils/axiosInstance';
import { CompanyDriverCardsRequest } from '../interfaces/Driver/Request/companyDriverCards';
import {
  addCompanyLocationRequest,
  CompanyLocationsRequest,
  updateCompanyLocationRequest,
} from '../interfaces/Location/Request/companyLocations';
import { CompanyDriversRequest } from '../interfaces/Driver/Request/companyDrivers';
import { CompanyVehiclesRequest } from '../interfaces/Vehicles/Request/companyVehicles';
import { GetUnusedSealsRequest } from '../interfaces/Seals/Request/getUnusedSeals';
import { AddNewVehicleRequest } from '../interfaces/Vehicles/Request/addNewVehicle';
import { GetCompanySealsRequest } from '../interfaces/Seals/Request/getCompanySeals';
import { AssignSealToCompanyRequest } from '../interfaces/Seals/Request/assignSealToCompany';
import { GetCompanyUsersRequest } from '../interfaces/Users/Request/getCompanyUsers';
import { AddNewUserRequest } from '../interfaces/Users/Request/addNewUser';
import { EditDriverDetails } from '../interfaces/Driver/Request/updateDriverDetails';
import { EditUserRequest } from '../interfaces/Users/Request/editUser';
import { GetAllClientsRequest } from '../interfaces/Company/Request/getAllClients';
import { AddClientRequest } from '../interfaces/Company/Request/addClient';
import { AxiosError } from 'axios';
import { AssignDriverRequest } from '../interfaces/Driver/Request/assignDriverRequest';
import { ApiErrorResponse, ApiResponse } from '../interfaces/Api/apiResponse';
import { GetAllSealsRequest } from '../interfaces/Seals/Request/getAllSeals';
import { GetAllSealsAdminRequest } from '../interfaces/Seals/Request/getAllSealsAdmin';
import { AssignSealAdminRequest } from '../interfaces/Seals/Request/assignSealsAdmin';
import { GetDriverRouteRequest } from '../interfaces/Driver/Request/getDriverRoute';
import { SkipAddressRequest } from '../interfaces/Route/Request/skipAddress';
import { CancelAddressRequest } from '../interfaces/Route/Request/cancelAddress';
import { EditClientRequest } from '../interfaces/Company/Request/editClient';
import { AssignSealToVehicleRequest } from '../interfaces/Seals/Request/assignSealToVehicle';
import { UnassignSealFromVehicleRequest } from '../interfaces/Seals/Request/unassignSealFromVehicle';
import { AddNewDriver } from '../interfaces/Driver/Request/addNewDriver';
import { AddNewTripRequest } from '../interfaces/Route/Request/addNewTrip';
import { EditVehicleRequest } from '../interfaces/Vehicles/Request/editVehicle';
import { GetCompanyOperatorsRequest } from '../interfaces/Operators/Request/GetCompanyOperators';
import { CreateOperatorRequest } from '../interfaces/Operators/Request/CreateOperator';
import { UpdateOperatorRequest } from '../interfaces/Operators/Request/UpdateOperator';
import { GetHistoryRequest } from '../interfaces/History/Request/getHistory';
import { UnassignDriverFromVehicleRequest } from '../interfaces/Route/Request/unassignDriverFromVehicle';
import { EditSettingsRequest } from '../interfaces/Company/Request/editSettings';
import { AddCommandRequest } from '../interfaces/Seals/Request/addCommand';

export const getCompanyDriverCards = async (
  data: CompanyDriverCardsRequest
) => {
  try {
    const response = await axiosInstance.post('/driver/company-drivers', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCompanyLocations = async (data: CompanyLocationsRequest) => {
  try {
    const response = await axiosInstance.post('/location/get-location', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addLocation = async (data: addCompanyLocationRequest) => {
  try {
    const response = await axiosInstance.post(
      '/location/create-location',
      data
    );
    return { success: true, data: response.data };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const editLocation = async (data: updateCompanyLocationRequest) => {
  try {
    const response = await axiosInstance.put('/location/update-location', data);
    return { success: true, data: response.data };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const deleteLocation = async (
  company_id: string,
  location_id: string
) => {
  try {
    await axiosInstance.delete(`/location/delete-location`, {
      params: { company_id, location_id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete location',
      };
    } else {
      throw error;
    }
  }
};

export const getCompanyDrivers = async (data: CompanyDriversRequest) => {
  try {
    const response = await axiosInstance.post('/driver/get-driver', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCompanyVehicles = async (data: CompanyVehiclesRequest) => {
  try {
    const response = await axiosInstance.post('/vehicle/get-vehicle', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addNewVehicle = async (data: AddNewVehicleRequest) => {
  try {
    const response = await axiosInstance.post('/vehicle/create-vehicle', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const editVehicle = async (data: EditVehicleRequest) => {
  try {
    const response = await axiosInstance.put('/vehicle/update-vehicle', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const deleteVehicle = async (id: string) => {
  try {
    await axiosInstance.delete(`/vehicle/delete-vehicle`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete Vehicle',
      };
    } else {
      throw error;
    }
  }
};

export const addNewUser = async (data: AddNewUserRequest) => {
  try {
    const response = await axiosInstance.post(`/user/create-user`, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const addNewDriver = async (data: AddNewDriver) => {
  try {
    const response = await axiosInstance.post('/driver/create-driver', data);

    if (response.data.statusCode === 201) {
      console.log('Driver created successfully:', response.data.data);
      return {
        success: true,
        message: 'Request successful',
        data: response.data.data,
      };
    } else {
      console.log('Unexpected response status code:', response.data);
      return {
        success: false,
        message: response.data.message || 'Unexpected error',
        error: response.data.error || null,
      };
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const {
        statusCode,
        message,
        error: responseError,
      } = error.response.data as ApiErrorResponse;

      if (statusCode === 409) {
        return {
          success: false,
          message: message || 'Driver email already exists',
          error: responseError || 'Conflict error',
        };
      }

      return {
        success: false,
        message: message || 'Server error',
        error: responseError || 'An error occurred while creating the driver',
      };
    }

    return {
      success: false,
      message: 'Network or unknown error occurred',
      error: (error as Error).message,
    };
  }
};

export const updateDriver = async (data: EditDriverDetails) => {
  try {
    const response = await axiosInstance.put('/driver/update-driver', data);

    if (response.data.statusCode === 200) {
      return {
        success: true,
        message: 'Driver updated successfully',
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || 'Unexpected error',
        error: response.data.error || null,
      };
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const { message, error: responseError } = error.response
        .data as ApiErrorResponse;

      return {
        success: false,
        message: message || 'Server error',
        error: responseError || 'An error occurred while updating the driver',
      };
    }

    return {
      success: false,
      message: 'Network or unknown error occurred',
      error: (error as Error).message,
    };
  }
};

export const assignDriverToVehicle = async (data: AssignDriverRequest) => {
  try {
    const response = await axiosInstance.put(
      '/route/assign-driver-to-vehicle',
      data
    );

    return {
      statusCode: response.data.statusCode,
      data: response.data.data,
      message: response.data.message,
      error: null,
    };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      const {
        statusCode,
        message,
        error: responseError,
      } = error.response.data as ApiResponse<null>;

      return {
        statusCode,
        data: null,
        message: message || 'An error occurred',
        error: responseError || 'Unknown error',
      };
    }

    return {
      statusCode: 500,
      data: null,
      message: 'Network or unknown error occurred',
      error: (error as Error).message,
    };
  }
};

export const deleteDriver = async (id: string) => {
  try {
    await axiosInstance.delete(`/driver/delete-driver`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete driver',
      };
    } else {
      throw error;
    }
  }
};

export const editUser = async (data: EditUserRequest) => {
  try {
    const response = await axiosInstance.put(`/user/update-user`, data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const getUnusedSeals = async (data: GetUnusedSealsRequest) => {
  try {
    const response = await axiosInstance.post('/seal/get-unused-seals', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getCompanySeals = async (data: GetCompanySealsRequest) => {
  try {
    const response = await axiosInstance.post('/seal/get-seal', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const assignSealToCompany = async (data: AssignSealToCompanyRequest) => {
  try {
    const response = await axiosInstance.put('/seal/claim-seal', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const getSealConfirmDummyCSV = async () => {
  try {
    const response = await axiosInstance.get(
      '/seal/dummy-seal-template-client'
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const uploadConfirmSealCsv = async (data: FormData) => {
  try {
    const response = await axiosInstance.post('/seal/claim-seals-csv', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const getCompanyUsers = async (data: GetCompanyUsersRequest) => {
  try {
    const response = await axiosInstance.post('/user/get-user', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getDriverRoute = async (data: GetDriverRouteRequest) => {
  try {
    const response = await axiosInstance.post(
      '/vehicle/get-vehicle-route',
      data
    );
    return response.data.data[0];
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAllClients = async (data: GetAllClientsRequest) => {
  try {
    const response = await axiosInstance.post(
      '/admin/get-all-company-info',
      data
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addClientsAdmin = async (data: AddClientRequest) => {
  try {
    const response = await axiosInstance.post('/company/create-company', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const editClientsAdmin = async (data: EditClientRequest) => {
  try {
    const response = await axiosInstance.put('/company/update-company', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const deleteCompany = async (id: string) => {
  try {
    await axiosInstance.delete(`/company/delete-company`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete Company',
      };
    } else {
      throw error;
    }
  }
};

export const getDummyCsv = async () => {
  try {
    const response = await axiosInstance.get('/seal/dummy-seal-template');
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const uploadSealCsv = async (data: FormData) => {
  try {
    const response = await axiosInstance.post('/seal/upload-seals-csv', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};

export const getAllSeals = async (data: GetAllSealsRequest) => {
  try {
    const response = await axiosInstance.post('/seal/get-seal', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAllSealsAdmin = async (data: GetAllSealsAdminRequest) => {
  try {
    const response = await axiosInstance.post('/admin/get-all-seals', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const assignMassSeals = async (data: AssignSealAdminRequest) => {
  try {
    const response = await axiosInstance.post('/admin/assign-seals', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const skipAddress = async (data: SkipAddressRequest) => {
  try {
    const response = await axiosInstance.put('/route/skip-trip', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const cancelAddress = async (data: CancelAddressRequest) => {
  try {
    const response = await axiosInstance.put('/route/cancel-trip', data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const assignSealToVehicle = async (data: AssignSealToVehicleRequest) => {
  try {
    const response = await axiosInstance.put(
      '/seal/assign-seal-to-vehicle',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const unassignSealFromVehicle = async (
  data: UnassignSealFromVehicleRequest
) => {
  try {
    const response = await axiosInstance.put(
      '/seal/remove-seal-from-vehicle',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const addToRoute = async (data: AddNewTripRequest) => {
  try {
    const response = await axiosInstance.post('/route/add-to-route', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const getCompanyOperators = async (data: GetCompanyOperatorsRequest) => {
  try {
    const response = await axiosInstance.post('/operator/get-operator', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const createCompanyOperator = async (data: CreateOperatorRequest) => {
  try {
    const response = await axiosInstance.post(
      '/operator/create-operator',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const updateCompanyOperator = async (data: UpdateOperatorRequest) => {
  try {
    const response = await axiosInstance.put('/operator/update-operator', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const deleteCompanyOperator = async (id: string) => {
  try {
    await axiosInstance.delete(`/operator/delete-operator`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete Operator',
      };
    } else {
      throw error;
    }
  }
};

export const deleteUser = async (id: string) => {
  try {
    await axiosInstance.delete(`/user/delete-user`, {
      params: { id },
    });
    return { success: true };
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return {
        success: false,
        message: error.response.data.message || 'Failed to delete user',
      };
    } else {
      throw error;
    }
  }
};

export const getHistory = async (data: GetHistoryRequest) => {
  try {
    const response = await axiosInstance.post('/history/get-history', data);
    return response.data.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const unassignDriverFromVehicle = async (
  data: UnassignDriverFromVehicleRequest
) => {
  try {
    const response = await axiosInstance.put(
      '/route/unassign-driver-from-vehicle',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const updateCompanySettings = async (data: EditSettingsRequest) => {
  try {
    const response = await axiosInstance.put(
      '/company/update-company-settings',
      data
    );
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

export const addCommand = async (data: AddCommandRequest) => {
  try {
    const response = await axiosInstance.post('/seal/add-command', data);
    return response.data;
  } catch (error: unknown) {
    if (error instanceof AxiosError && error.response) {
      return error.response.data;
    } else {
      console.error('Unknown error occurred:', (error as Error).message);
      throw error;
    }
  }
};

import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as EllipsVertIcon } from '../../assets/icons/ellipsis-vertical.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/magnifying-glass.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

import Pagination from '../Pagination/Pagination';
import modalTheme from '../../theme/modalTheme';
import { assignMassSeals, getAllClients } from '../../services/apiService';
import { GetAllClientsResponse } from '../../interfaces/Company/Response/getAllClients';
import { useCookies } from 'react-cookie';
import { GetAllClientsRequest } from '../../interfaces/Company/Request/getAllClients';
import { GetAllSealsAdminResponse } from '../../interfaces/Seals/Response/getAllSealsAdmin';
import SnackbarComponent from '../Snackbar/SnackbarComponent';
import NoDataState from './NoDataState';
import { useTranslation } from 'react-i18next';

interface MassSelectTableProps {
  title: string;
  searchValue: string;
  columns: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<{ [key: string]: any }>;
  columnFieldMapping: { [key: string]: string };
  columnWidths: string[];
  setData: (data: Array<{ [key: string]: unknown }>) => void;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPlusIconClick: () => void;
}

const MassSelectTable: React.FC<MassSelectTableProps> = ({
  title,
  searchValue,
  columns,
  data,
  columnFieldMapping,
  columnWidths,
  setData,
  onSearchValueChange,
  onPlusIconClick,
}) => {
  const [cookies] = useCookies(['user']);
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: unknown;
  }>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const [timePassed, setTimePassed] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setTimePassed(true);
    }, 1500);
  }, []);

  const handleSelectRow = (index: number, isValid: boolean) => {
    setSelectedRows((prevSelectedRows) => {
      let newSelectedRows = { ...prevSelectedRows };
      if (index in newSelectedRows) {
        delete newSelectedRows[index];
      } else {
        newSelectedRows = { ...newSelectedRows, [index]: isValid };
      }
      return newSelectedRows;
    });
  };

  const handleIconClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setLastUpdatedTime(getCurrentTime());
  }, [data]);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSort = (column: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        const aValue = a[columnFieldMapping[sortConfig.key]];
        const bValue = b[columnFieldMapping[sortConfig.key]];

        if (aValue === null || aValue === '-')
          return sortConfig.direction === 'asc' ? 1 : -1;
        if (bValue === null || bValue === '-')
          return sortConfig.direction === 'asc' ? -1 : 1;

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig, columnFieldMapping]);

  const calculateTotalPages = (totalEntries: number, rowsPerPage: number) => {
    return Math.ceil(totalEntries / rowsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = useMemo(() => {
    return sortedData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [sortedData, currentPage, rowsPerPage]);

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: { [key: string]: unknown }
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const calculateRowsPerPage = () => {
    const rowHeight = 60;
    const headerHeight = 180;
    const footerHeight = 180;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    return Math.floor(availableHeight / rowHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      const newRowsPerPage = calculateRowsPerPage();
      if (newRowsPerPage < 1) {
        setRowsPerPage(1);
      } else {
        setRowsPerPage(newRowsPerPage);
      }
      setCurrentPage(1);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTotalPages(calculateTotalPages(data.length, rowsPerPage));
  }, [rowsPerPage, data]);

  const handleSelectAllAction = () => {
    if (Object.keys(selectedRows).length === 0) {
      const newSelectedRows = data.reduce((acc, row) => {
        acc[row.id] = row.status !== 'IN_USE';
        return acc;
      }, {} as { [key: number]: boolean });
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows({});
    }
  };

  // Items pertaining to mass asign seal

  const [openMassAssignSeal, setOpenMassAssignSeal] = useState(false);
  const [massAssignFormValues, setMassAssignFormValues] = useState<{
    company_id: string;
  }>({
    company_id: ' ',
  });
  const [allClients, setAllClients] = useState<GetAllClientsResponse[]>([]);
  const [hasFalseSelection, setHasFalseSelection] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const payload: GetAllClientsRequest = {
          admin_email: cookies.user.email,
          search_term: '',
        };
        const data = await getAllClients(payload);
        setAllClients(data);
      } catch {
        setSnackbarMessage('Error fetching client list.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchClients();
  }, [cookies.user.email]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === 'Enter' &&
      massAssignFormValues.company_id.trim() !== ''
    ) {
      event.preventDefault();
    }
  };

  const handleMassAssignSeal = () => {
    const assignSeals = async () => {
      const selectedSeals = Object.keys(selectedRows);

      const payload = {
        admin_email: cookies.user.email,
        company_id: massAssignFormValues.company_id,
        seals: selectedSeals,
      };

      try {
        const returnData = await assignMassSeals(payload);

        const dataMap = new Map(data.map((item) => [item.id, item]));

        returnData.forEach((item: GetAllSealsAdminResponse) => {
          dataMap.set(item.id, item);
        });

        const finalData = Array.from(dataMap.values());

        setData(finalData);
        setSelectedRows({});
        setOpenMassAssignSeal(false);
        setSnackbarMessage('Successfully assigned seals');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch {
        setSnackbarMessage('Error assigning seals');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };
    assignSeals();
  };

  useEffect(() => {
    if (openMassAssignSeal) {
      const hasFalseEntry = Object.entries(selectedRows).some(
        ([, value]) => !value
      );

      setHasFalseSelection(hasFalseEntry);

      const newSelectedRows = Object.entries(selectedRows).reduce(
        (acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        },
        {} as { [key: string]: boolean }
      );

      setSelectedRows(newSelectedRows);
    } else {
      massAssignFormValues.company_id = ' ';
      setHasFalseSelection(false);
    }
    // eslint-disable-next-line
  }, [openMassAssignSeal]);

  return (
    <Grid2
      container
      display={'flex'}
      direction={'column'}
      spacing={2}
      sx={{
        height: '100%',
        minHeight: '250px',
        maxWidth: '100%',
      }}
    >
      <Grid2 container display={'flex'} alignItems={'flex-end'}>
        <Typography variant="h4">{t(title)}</Typography>
        <Typography
          style={{
            fontSize: '12px',
            color: '#86BE02',
          }}
        >
          {t('Last updated at')} {lastUpdatedTime}
        </Typography>
      </Grid2>
      <Grid2
        display={'flex'}
        alignItems={'center'}
        sx={{
          height: '40px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'rgba(131, 131, 131, 0.4)',
          minWidth: '100%',
        }}
      >
        <Grid2
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{
            width: '44.5px',
            height: '100%',
            backgroundColor:
              Object.keys(selectedRows).length === 0 ? '#FFFFFF' : '#B4EE2B',
            borderRadius: '14px 0px 0px 14px',
            border: '1px solid',
            borderColor:
              Object.keys(selectedRows).length === 0
                ? 'transparent'
                : '#B4EE2B',
          }}
        >
          {Object.keys(selectedRows).length === 0 ? (
            <PlusIcon
              onClick={onPlusIconClick}
              cursor={'pointer'}
              style={{
                width: '16px',
                height: '16px',
                padding: '8px 4px',
              }}
            />
          ) : (
            <LinkIcon
              onClick={() => setOpenMassAssignSeal(true)}
              cursor={'pointer'}
              style={{
                width: '20px',
                height: '20px',
                padding: '8px 4px',
              }}
            />
          )}
        </Grid2>
        <Grid2
          container
          display={'flex'}
          alignItems={'center'}
          sx={{
            height: '100%',
            width: '100%',
            borderLeft: '1px solid rgba(131, 131, 131, 0.4)',
          }}
        >
          <Grid2
            display={'flex'}
            alignItems={'center'}
            sx={{
              marginLeft: '10px',
            }}
          >
            <SearchIcon
              onClick={handleIconClick}
              cursor={'text'}
              style={{
                fill: '#838383',
                width: '15px',
                height: '15px',
                marginRight: '10px',
              }}
            />
          </Grid2>
          <Grid2
            sx={{
              width: '80%',
            }}
          >
            <TextField
              inputRef={textFieldRef}
              sx={{
                'width': '100%',
                'padding': '0px',
                '& .MuiOutlinedInput-root': {
                  'padding': '0px',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '0px',
                },
              }}
              value={searchValue}
              onChange={onSearchValueChange}
              placeholder={t('Search')}
              variant="outlined"
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2>
        {data.length === 0 && timePassed ? (
          <NoDataState />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
            }}
          >
            <Table sx={{ borderSpacing: '0 8px', borderCollapse: 'separate' }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: '5%',
                      borderBottom: 'none',
                      color: 'rgba(131, 131, 131, 0.4)',
                    }}
                  >
                    <Checkbox
                      checked={Object.keys(selectedRows).length !== 0}
                      onClick={handleSelectAllAction}
                      checkedIcon={
                        <SvgIcon
                          sx={{
                            width: '20px',
                            height: '20px',
                          }}
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                        </SvgIcon>
                      }
                      sx={{
                        'padding': '0',
                        '& .MuiSvgIcon-root': {
                          width: '20px',
                          height: '20px',
                        },
                        '&.Mui-checked': {
                          color: '#000',
                        },
                      }}
                    />
                  </TableCell>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        width: columnWidths[index],
                        cursor: 'pointer',
                        borderBottom: 'none',
                        color: 'rgba(131, 131, 131, 0.4)',
                      }}
                      onClick={() => handleSort(column)}
                    >
                      {t(column)}
                      {sortConfig && sortConfig.key === column ? (
                        sortConfig.direction === 'asc' ? (
                          <ArrowUpIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        ) : (
                          <ArrowDownIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor:
                        rowIndex % 2 === 1 ? '#F5F5F5' : '#FBFBFB',
                    }}
                  >
                    <TableCell
                      sx={{
                        borderRadius: '16px 0 0 16px',
                        width: '20px',
                        maxWidth: '20px',
                      }}
                      style={{
                        borderBottom: 'none',
                        color: 'rgba(131, 131, 131, 0.4)',
                      }}
                    >
                      <Checkbox
                        checked={row.id in selectedRows}
                        onChange={() =>
                          handleSelectRow(row.id, row.status !== 'IN_USE')
                        }
                        sx={{
                          'padding': '0',
                          '& .MuiSvgIcon-root': {
                            width: '20px',
                            height: '20px',
                          },
                          '&.Mui-checked': {
                            color: '#000',
                          },
                        }}
                      />
                    </TableCell>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          width: columnWidths[colIndex],
                          borderBottom: 'none',
                          padding: '8px 16px',
                        }}
                      >
                        {row[columnFieldMapping[column]] === null ||
                        row[columnFieldMapping[column]] === '' ? (
                          '-'
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'IN_USE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Active')}
                          </Typography>
                        ) : column === 'Status' &&
                          row[columnFieldMapping[column]] === 'REGISTERED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Awaiting activation')}
                          </Typography>
                        ) : (
                          row[columnFieldMapping[column]]
                        )}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: 'none',
                        borderRadius: '0 16px 16px 0',
                        padding: '8px',
                      }}
                    >
                      <IconButton
                        onClick={(event) => handleActionClick(event, row)}
                      >
                        <EllipsVertIcon
                          style={{
                            fill: '#000000',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow === row}
                        onClose={handleClose}
                        sx={{
                          '& .MuiPaper-root': {
                            borderRadius: '20px',
                          },
                        }}
                      >
                        <MenuItem
                          key={'Assign'}
                          disabled={row.status === 'IN_USE'}
                          onClick={() => {
                            setSelectedRows({ [row.id]: true });
                            setOpenMassAssignSeal(true);
                            handleClose();
                          }}
                          sx={{
                            padding: '15px 20px',
                          }}
                        >
                          <LinkIcon
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          ></LinkIcon>
                          <Typography
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {t('Assign this device to Client')}
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid2>
      {data.length === 0 ? null : (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
      <ThemeProvider theme={modalTheme}>
        <Dialog
          open={openMassAssignSeal}
          onClose={() => setOpenMassAssignSeal(false)}
        >
          <DialogTitle>{t('Assign Device(s) to Client')}</DialogTitle>
          <Typography variant="subtitle1">
            {t(
              'All selected iMSeal devices will be assigned to the selected client. Once the client has activated the devices this action is permanent.'
            )}
          </Typography>
          <DialogContent>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '20px',
                textAlign: 'left',
              }}
            >
              {t('Select Client')}
            </Typography>
            <Autocomplete
              options={allClients}
              getOptionLabel={(option) => option.client}
              value={
                allClients.find(
                  (v) => v.id === massAssignFormValues.company_id
                ) || null
              }
              onChange={(event, newValue) => {
                setMassAssignFormValues({
                  company_id: newValue?.id || '',
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('Company Name')}
                  fullWidth
                  onKeyDown={handleKeyDown}
                  sx={{ mb: 2, backgroundColor: 'white' }}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            style={{
                              height: '14px',
                              width: '15px',
                            }}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            />
          </DialogContent>
          {hasFalseSelection && (
            <Typography variant="caption" color="#FF9500">
              {t(
                'One or more selected items are already activated and will be automatically excluded from this action.'
              )}
            </Typography>
          )}
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setOpenMassAssignSeal(false)}
              color="primary"
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={massAssignFormValues.company_id === ' '}
              onClick={handleMassAssignSeal}
              color="primary"
            >
              {t('Confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </Grid2>
  );
};

export default MassSelectTable;

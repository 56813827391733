import { createTheme } from '@mui/material';

const modalTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '26px',
          borderRadius: '16px',
          maxWidth: '480px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
          color: '#101828',
          fontSize: '1.5rem',
          fontWeight: 600,
          padding: '10px 24px 0px 24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px 24px',
          backgroundColor: '#FBFBFB',
          borderRadius: '20px',
          marginTop: '16px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 0px',
          justifyContent: 'flex-end',
          marginTop: '16px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
          backgroundColor: 'white',
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'textTransform': 'none',
          'fontWeight': 400,
          'fontFamily': 'Open Sans',
          'borderRadius': '12px',
          'padding': '6px 16px',
          '&.Mui-disabled': {
            backgroundColor: '#EEEEEE',
            color: '#C7C7C7',
          },
        },
        outlined: {
          borderColor: '#E5E5E5',
          color: '#000000',
        },
        contained: {
          backgroundColor: '#000000',
          color: '#ffffff',
          boxShadow: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
        },
        subtitle1: {
          padding: '0 24px',
          fontSize: '1rem',
          fontWeight: '500',
          color: '#838383',
        },
        subtitle2: {
          fontSize: '1rem',
          marginBottom: '4px',
        },
        caption: {
          padding: '0 24px',
          color: '#d32f2f',
        },
        h6: {
          fontSize: '0.8rem',
          color: '#838383',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'borderRadius': '8px',
          'height': '44px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C7C7C7',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C7C7C7',
          },
          '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C7C7C7',
          },
        },
        input: {
          'padding': '10px 14px',
          '&::placeholder': {
            color: '#C7C7C7',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'borderRadius': '8px',
          'margin': '0px 8px',
          '&:hover': {
            backgroundColor: '#F5F5F5',
          },
          '&.Mui-selected': {
            backgroundColor: '#E0E0E0',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#D5D5D5',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          'borderRadius': '8px',
          'backgroundColor': '#FFFFFF',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
        },
        icon: {
          color: '#000000',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          marginTop: '4px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

export default modalTheme;

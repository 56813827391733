import { Box, ButtonBase, Grid2, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{
        position: 'absolute',
        bottom: 0,
        width: 'calc(100% - 150px)',
        margin: '20px 30px',
      }}
    >
      <Grid2
        container
        display={'flex'}
        justifyContent={'space-between'}
        sx={{
          width: '100%',
        }}
      >
        <Grid2>
          <Typography
            sx={{
              color: '#838383',
            }}
          >
            Ⓒ 2024 {t('GritCargo')}
          </Typography>
        </Grid2>
        <Grid2 container spacing={2}>
          <Grid2>
            <ButtonBase>
              <Typography
                sx={{
                  color: '#838383',
                }}
              >
                {t('Support')}
              </Typography>
            </ButtonBase>
          </Grid2>
          <Grid2>
            <ButtonBase onClick={() => navigate('/termsandconditions')}>
              <Typography
                sx={{
                  color: '#838383',
                }}
              >
                {t('Terms & Conditions')}
              </Typography>
            </ButtonBase>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Footer;

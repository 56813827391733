import { Grid2 } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getAllSealsAdmin } from '../../../services/apiService';
import useDebounce from '../../../utils/useDebounce';
import MassSelectTable from '../../../components/Table/MassSelectTable';

import AddDevicesCSV from './Components/AddDevicesCSV';
import { GetAllSealsAdminRequest } from '../../../interfaces/Seals/Request/getAllSealsAdmin';
import { GetAllSealsAdminResponse } from '../../../interfaces/Seals/Response/getAllSealsAdmin';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import { getSearch } from '../../../utils/searchUtil';

const AdminSeal: React.FC = () => {
  const [cookies] = useCookies(['user']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [seals, setSeals] = useState<GetAllSealsAdminResponse[]>([]);
  const [openCSVUpload, setOpenCSVUpload] = useState(false);

  const columnFieldMapping = {
    'Serial Number': 'serial_number',
    'Client': 'company_name',
    'Status': 'status',
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    setSearchValue(getSearch().search);
  }, []);

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['30%', '25%', '25%'];

  const fetchSeals = async () => {
    try {
      const payload: GetAllSealsAdminRequest = {
        admin_email: cookies.user.email,
        search_term: debouncedSearchValue,
      };
      const data = await getAllSealsAdmin(payload);
      setSeals(data);
    } catch {
      setSnackbarMessage('Error fetching seals');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchSeals();
    // eslint-disable-next-line
  }, [debouncedSearchValue, cookies.user.email]);

  const handlePlusIconClick = () => {
    setOpenCSVUpload(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <MassSelectTable
          title="iMSeal Locks"
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={seals}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setData={(data: { [key: string]: any }[]) =>
            setSeals(data as GetAllSealsAdminResponse[])
          }
          columnFieldMapping={columnFieldMapping}
          columnWidths={columnWidths}
        />
      </Grid2>
      <AddDevicesCSV
        open={openCSVUpload}
        onClose={() => setOpenCSVUpload(false)}
        onUploadSuccess={fetchSeals}
      />
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
    </Grid2>
  );
};

export default AdminSeal;

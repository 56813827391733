import React, { useEffect, useState } from 'react';
import TableComponent from '../../../components/Table/TableComponent';
import {
  getCompanyLocations,
  addLocation,
  editLocation,
} from '../../../services/apiService';
import { CompanyLocationsResponse } from '../../../interfaces/Location/Response/companyLocations';
import {
  CompanyLocationsRequest,
  addCompanyLocationRequest,
} from '../../../interfaces/Location/Request/companyLocations';
import useDebounce from '../../../utils/useDebounce';
import { Grid2 } from '@mui/material';

import { ReactComponent as EditIcon } from '../../../assets/icons/warehouse.svg';
import { ReactComponent as ViewMapIcon } from '../../../assets/icons/location-arrow.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { useCookies } from 'react-cookie';
import SnackbarComponent from '../../../components/Snackbar/SnackbarComponent';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { deleteLocation } from '../../../services/apiService';
import LocationModal from './Components/LocationModal';
import { setLocation } from '../../../utils/locationUtil';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Locations() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user', 'role']);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [locations, setLocations] = useState<CompanyLocationsResponse[]>([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [locationToDelete, setLocationToDelete] =
    useState<CompanyLocationsResponse | null>(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useTranslation();

  const [LocationModalValues, setLocationModalValues] = useState({
    id: '',
    name: '',
    address: '',
    lat: '',
    lng: '',
  });

  const columnFieldMapping = {
    'Location Name': 'name',
    'Address': 'address',
    'Latitude': 'latitude',
    'Longitude': 'longitude',
  };

  const columns = Object.keys(columnFieldMapping);

  const columnWidths = ['12%', '45%', '5%', '48%'];

  const actions = (row: { [key: string]: unknown }) => [
    {
      label: 'Edit Details',
      icon: <EditIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setIsEditMode(true);
        setLocationModalValues({
          id: row.id as string,
          name: row.name as string,
          address: row.address as string,
          lat: row.latitude as string,
          lng: row.longitude as string,
        });
        setOpenLocationModal(true);
      },
    },
    {
      label: 'Delete Location',
      icon: <TrashIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setLocationToDelete(row as unknown as CompanyLocationsResponse);
        setOpenDeleteModal(true);
      },
    },
    {
      label: 'View on Map',
      icon: <ViewMapIcon style={{ width: '18px', marginRight: '8px' }} />,
      onClick: () => {
        setLocation(
          parseFloat(row.latitude as string),
          parseFloat(row.longitude as string)
        );
        navigate('/');
      },
    },
  ];

  const handlePlusIconClick = () => {
    setIsEditMode(false);
    setOpenLocationModal(true);
  };

  const handleLocationModalClose = () => {
    setOpenLocationModal(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleLocationModalSubmit = async () => {
    if (isEditMode && LocationModalValues) {
      const payload = {
        company_id: cookies.user.company_id,
        location_id: LocationModalValues.id,
        name: LocationModalValues.name,
      };

      try {
        const response = await editLocation(payload);
        if (response.success) {
          setSnackbarMessage('Location updated successfully');
          setSnackbarSeverity('success');
          refetchInfo();
        } else {
          setSnackbarMessage('Failed to update location');
          setSnackbarSeverity('error');
        }
      } catch {
        setSnackbarMessage('An error occurred while updating location');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenLocationModal(false);
      }
    } else {
      const payload: addCompanyLocationRequest = {
        company_id: cookies.user.company_id,
        name: LocationModalValues.name,
        address: LocationModalValues.address,
        latitude: parseFloat(LocationModalValues.lat),
        longitude: parseFloat(LocationModalValues.lng),
      };

      try {
        const response = await addLocation(payload);
        if (response.success) {
          setLocations((prevLocations) => [...prevLocations, response.data]);
          setSnackbarSeverity('success');
          setSnackbarMessage('Location added successfully');
          refetchInfo();
        } else {
          setSnackbarSeverity('error');
          setSnackbarMessage(response.message || 'Failed to add location');
        }
      } catch {
        setSnackbarMessage('Could not add location');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenLocationModal(false);
      }
    }
  };

  const handleDeleteLocation = async () => {
    if (locationToDelete) {
      try {
        const response = await deleteLocation(
          cookies.user.company_id,
          locationToDelete.id!
        );
        if (response.success) {
          setLocations((prevLocations) =>
            prevLocations.filter(
              (location) => location.id !== locationToDelete.id
            )
          );
          setSnackbarMessage('Location deleted successfully');
          setSnackbarSeverity('success');
        } else {
          setSnackbarMessage(response.message || 'Failed to delete location');
          setSnackbarSeverity('error');
        }
      } catch {
        setSnackbarMessage('Could not delete location');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
        setOpenDeleteModal(false);
      }
    }
  };

  useEffect(() => {}, [debouncedSearchValue]);

  useEffect(() => {
    const fetchLocations = async () => {
      const payload: CompanyLocationsRequest = {
        company_id: cookies.user.company_id,
        search_term: debouncedSearchValue,
      };
      try {
        const data = await getCompanyLocations(payload);
        setLocations(data);
      } catch {
        setSnackbarMessage('Could not fetch Locations');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    fetchLocations();
  }, [debouncedSearchValue, cookies.user.company_id]);

  const refetchInfo = async () => {
    const payload: CompanyLocationsRequest = {
      company_id: cookies.user.company_id,
      search_term: debouncedSearchValue,
    };
    try {
      const data = await getCompanyLocations(payload);
      setLocations(data);
    } catch {
      setSnackbarMessage('Could not fetch Locations');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Grid2
      container
      display={'flex'}
      sx={{ width: '100%', height: '100%', padding: '20px' }}
    >
      <Grid2
        sx={{
          width: '100%',
        }}
      >
        <TableComponent
          title={t('Locations')}
          onPlusIconClick={handlePlusIconClick}
          onSearchValueChange={handleSearchChange}
          searchValue={searchValue}
          columns={columns}
          data={locations}
          columnFieldMapping={columnFieldMapping}
          actions={actions}
          columnWidths={columnWidths}
        />
      </Grid2>
      <DeleteModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteLocation}
        type="Location"
        name={locationToDelete?.name || ''}
      />

      <LocationModal
        open={openLocationModal}
        onClose={handleLocationModalClose}
        onSubmit={handleLocationModalSubmit}
        isEditMode={isEditMode}
        formValues={LocationModalValues}
        setFormValues={setLocationModalValues}
      />

      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </Grid2>
  );
}

export default Locations;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Icon, Button, ThemeProvider } from '@mui/material';
import modalTheme from '../../theme/modalTheme';
import { ReactComponent as CloseIcon } from '../../assets/icons/circle-xmark.svg';
import { ReactComponent as ApproveIcon } from '../../assets/icons/circle-check.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as SkipIcon } from '../../assets/icons/skip.svg';
import { ReactComponent as UnlockIcon } from '../../assets/icons/unlock.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/circle-check.svg';
import { ReactComponent as FailureIcon } from '../../assets/icons/circle-xmark.svg';
import { useNavigate } from 'react-router-dom';
import { setLocation } from '../../utils/locationUtil';
import { AddCommandRequest } from '../../interfaces/Seals/Request/addCommand';
import { addCommand } from '../../services/apiService';
import SnackbarComponent from '../Snackbar/SnackbarComponent';
import { useTranslation } from 'react-i18next';
import { skipAddress } from '../../services/apiService';
import { useCookies } from 'react-cookie';

interface NotificationTemplateProps {
  uniqueKey: string;
  text: string;
  details: {
    serial_number: string;
    contact_number: string;
    vehicle_alias: string;
    driver_id: string;
    driver_name: string;
    latitude: string;
    longitude: string;
    seal_id: string;
    company_id: string;
    unlock_left: boolean;
    route_id?: string;
    trip_id?: string;
    reAdd?: boolean;
    success?: boolean;
  };
  onClose: (uniqueKey: string) => void;
}

const NotificationTemplate: React.FC<NotificationTemplateProps> = ({
  uniqueKey,
  text,
  details,
  onClose,
}) => {
  const [cookies] = useCookies(['user']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [notificationType, setNotificationType] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarDuration, setSnackbarDuration] = useState(2000);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const determineNotificationType = (text: string): string => {
    switch (true) {
      case text.includes('Skip Trip Request'):
        return 'Skip';
      case text.includes('Request Unlock'):
        return 'Unlock';
      case text.includes('Request Lock'):
        return 'Lock';
      case text.includes('Feedback on lock/unlock request'):
        return 'Feedback';
      default:
        return 'Default';
    }
  };

  useEffect(() => {
    setNotificationType(determineNotificationType(text));
  }, [text]);

  const handleSubmitLock = async (
    unlockLeft: boolean,
    currentState: boolean,
    serial_number: string,
    company_id: string
  ) => {
    const payload: AddCommandRequest = {
      company_id: company_id,
      seal_serial_number: serial_number,
      actioned_by:
        cookies.user.first_name +
        ' ' +
        cookies.user.last_name +
        ' - ' +
        cookies.user.role,
      command_code: unlockLeft
        ? currentState
          ? 'UL'
          : 'LK'
        : currentState
        ? 'UR'
        : 'LK',
    };

    const response = await addCommand(payload);

    if (response.statusCode === 400) {
      setSnackbarMessage(response.message);
      setSnackbarSeverity('error');
      setSnackbarDuration(2000);
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage(
        response.data.message +
          ' - ' +
          t('Expected time to complete in seconds') +
          ': ' +
          Math.round(response.data.expectedPingResponseTime)
      );
      setSnackbarSeverity('success');
      setSnackbarDuration(3000);
      setSnackbarOpen(true);
      onClose(uniqueKey);
    }
  };

  const handleApproveClick = async () => {
    try {
      const payload = {
        route_id: details.route_id ?? '',
        trip_id: details.serial_number,
        reAdd: false,
      };
      await skipAddress(payload);
      setSnackbarMessage('Address successfully skipped.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      onClose(uniqueKey);
    } catch {
      setSnackbarMessage('Error skipping address.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      onClose(uniqueKey);
    }
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '10px 24px 24px 24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor:
              notificationType === 'Skip'
                ? '#FFE6C8'
                : notificationType === 'Lock'
                ? '#FFE6C8'
                : notificationType === 'Feedback'
                ? details.success
                  ? '#34C7591A'
                  : '#FF3B301A'
                : '#30D1581A',
            borderRadius: '12px',
            padding: '10px',
            width: '18px',
            height: '18px',
            marginRight: '16px',
          }}
        >
          {notificationType === 'Skip' ? (
            <SkipIcon
              style={{
                width: '20px',
                height: '20px',
                stroke: '#FF9500',
              }}
            />
          ) : notificationType === 'Lock' ? (
            <UnlockIcon
              style={{
                width: '20px',
                height: '20px',
                fill: '#FF9500',
              }}
            />
          ) : notificationType === 'Feedback' ? (
            details.success ? (
              <SuccessIcon
                style={{
                  width: '20px',
                  height: '20px',
                  stroke: '#34C759',
                }}
              />
            ) : (
              <FailureIcon
                style={{
                  width: '20px',
                  height: '20px',
                  stroke: '#FF3B30',
                }}
              />
            )
          ) : (
            <LockIcon
              style={{
                width: '20px',
                height: '20px',
                fill: '#34C759',
              }}
            />
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            style={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {notificationType === 'Feedback' ? (
              <>{details.serial_number}</>
            ) : (
              <>
                {details.vehicle_alias} - {details.serial_number}
              </>
            )}
          </Typography>
          <Typography
            style={{
              fontFamily: 'Open Sans',
              fontSize: '12px',
              fontWeight: 400,
              textAlign: 'left',
            }}
          >
            {notificationType === 'Skip'
              ? `${details.driver_name} Requested permission to skip their current delivery address.`
              : notificationType === 'Lock'
              ? `${details.driver_name} Requested permission to lock the device.`
              : notificationType === 'Feedback'
              ? details.success
                ? 'The Lock/Unlock request was successful.'
                : 'The Lock/Unlock request was unsuccessful.'
              : `${details.driver_name} Requested permission to unlock within Geozone.`}
            <span
              style={{
                cursor: 'pointer',
                marginLeft: '8px',
                textDecoration: 'underline',
              }}
              onClick={() => {
                setLocation(
                  parseFloat(details.latitude),
                  parseFloat(details.longitude)
                );
                navigate('/');
              }}
            >
              {t('View on Map')}
            </span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              marginTop: '8px',
              width: '100%',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => onClose(uniqueKey)}
              sx={{ height: '28px' }}
              fullWidth
            >
              <Icon
                component={CloseIcon}
                sx={{ height: '16px', width: '16px', mr: '4px' }}
              />
              {notificationType === 'Feedback' ? 'Dismiss' : 'Deny'}
            </Button>
            {notificationType !== 'Feedback' ? (
              <>
                {notificationType === 'Skip' ? (
                  <Button
                    variant="contained"
                    sx={{ height: '28px', fontSize: '12px' }}
                    fullWidth
                    onClick={handleApproveClick}
                  >
                    <Icon
                      component={ApproveIcon}
                      sx={{ height: '16px', width: '16px', mr: '4px' }}
                    />
                    Approve
                  </Button>
                ) : notificationType === 'Lock' ? (
                  <Button
                    variant="contained"
                    sx={{ height: '28px', fontSize: '12px' }}
                    fullWidth
                    onClick={() =>
                      handleSubmitLock(
                        details.unlock_left,
                        false,
                        details.serial_number,
                        details.company_id
                      )
                    }
                  >
                    <Icon
                      component={LockIcon}
                      sx={{
                        height: '16px',
                        width: '16px',
                        mr: '4px',
                        fill: 'white',
                      }}
                    />
                    {details.unlock_left ? 'Lock Left' : 'Lock Right'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ height: '28px', fontSize: '12px' }}
                    fullWidth
                    onClick={() =>
                      handleSubmitLock(
                        details.unlock_left,
                        true,
                        details.serial_number,
                        details.company_id
                      )
                    }
                  >
                    <Icon
                      component={UnlockIcon}
                      sx={{
                        height: '16px',
                        width: '16px',
                        mr: '4px',
                        fill: 'white',
                      }}
                    />
                    {details.unlock_left ? 'Unlock Left' : 'Unlock Right'}
                  </Button>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        duration={snackbarDuration}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
};

export default NotificationTemplate;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, Link } from '@mui/material';
import { useCookies } from 'react-cookie';
import { getCompanyVehicles } from '../../services/apiService';
import { useTranslation } from 'react-i18next';

const Breadcrumbs: React.FC = () => {
  const [cookies] = useCookies(['user']);
  const location = useLocation();
  const [alias, setAlias] = useState('');
  const pathnames = location.pathname.split('/').filter((x) => x);
  const { t } = useTranslation();

  const capitalizeFirstLetter = (string: string) => {
    if (string.toLowerCase() === 'seals') {
      return t('iMSeal List');
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getVehicleAlias = async (vehicleId: string) => {
    const payload = {
      company_id: cookies.user.company_id,
      search_term: '',
      vehicle_id: vehicleId,
    };

    const response = await getCompanyVehicles(payload);
    setAlias(response.vehicle_alias);
  };

  const isAdminPage = pathnames[0] === 'admin';

  return (
    <div style={{ padding: '16px 16px 16px 32px' }}>
      <MUIBreadcrumbs
        aria-label="breadcrumb"
        separator={
          <span
            style={{
              margin: '0 16px',
              color: 'var(--black-20, #C7C7C7)',
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 350,
            }}
          >
            /
          </span>
        }
      >
        {!isAdminPage && (
          <Link
            href="/"
            underline="hover"
            style={{
              color: 'var(--black-40, #838383)',
              fontFamily: 'Open Sans',
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            {t('GritCargo')}
          </Link>
        )}
        {isAdminPage && (
          <Link
            href="/admin/clients"
            underline="hover"
            style={{
              color: 'var(--black-40, #838383)',
              fontFamily: 'Open Sans',
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            {t('Admin')}
          </Link>
        )}
        {pathnames.map((value, index) => {
          if (isAdminPage && index === 0) return null;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const isVehicleId = isLast && value.length === 36;

          if (isVehicleId) {
            getVehicleAlias(value);
          }

          return (
            <Link
              href={to}
              key={to}
              underline="hover"
              style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 350,
                color: isLast
                  ? 'var(--black-100, #000000)'
                  : 'var(--black-40, #838383)',
              }}
            >
              {isLast && isVehicleId
                ? capitalizeFirstLetter(alias.replace(/-/g, ' '))
                : capitalizeFirstLetter(value.replace(/-/g, ' '))}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;

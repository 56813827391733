import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import modalTheme from '../../../../theme/modalTheme';
import {
  isValidPhoneNumber,
  parsePhoneNumber,
  CountryCode,
} from 'libphonenumber-js';
import StyledPhoneInput from '../../../../components/PhoneNumber/StyledPhoneInput';
import { useTranslation } from 'react-i18next';

interface UserModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isEditMode?: boolean;
  formValues: {
    role: string;
    first_name: string;
    last_name: string;
    contact_number: string;
    email: string;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      role: string;
      first_name: string;
      last_name: string;
      contact_number: string;
      email: string;
    }>
  >;
}

const UserModal: React.FC<UserModalProps> = ({
  open,
  onClose,
  isEditMode = false,
  formValues,
  setFormValues,
  onSubmit,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({
        role: '',
        first_name: '',
        last_name: '',
        contact_number: '',
        email: '',
      });
      setSelectedCountry('za');
      setPhoneError('');
      setEmailError('');
    }
  }, [open, setFormValues]);

  const [selectedCountry, setSelectedCountry] = useState('za');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(true);
  const { t } = useTranslation();

  const handlePhoneChange = (value: string, country: CountryData) => {
    setFormValues({ ...formValues, contact_number: value });
    setSelectedCountry(country.countryCode);
    validatePhoneNumber(value, country);
  };

  useEffect(() => {
    if (isEditMode) {
      setIsPhoneEmpty(false);
    }
  }, [isEditMode]);

  const validateEmail = (email: string) => {
    if (email.trim() === '') {
      setEmailError('');
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError(t('Please enter a valid email address'));
    } else {
      setEmailError('');
    }
  };

  const validatePhoneNumber = (value: string, country: CountryData) => {
    const dialCode = country.dialCode;

    if (value === dialCode) {
      setPhoneError('');
      setIsPhoneEmpty(true);
      return;
    }

    try {
      parsePhoneNumber(value, country.countryCode.toUpperCase() as CountryCode);
      if (
        !isValidPhoneNumber(
          value,
          country.countryCode.toUpperCase() as CountryCode
        )
      ) {
        setPhoneError(t('Invalid phone number'));
        setIsPhoneEmpty(false);
      } else {
        setPhoneError('');
        setIsPhoneEmpty(false);
      }
    } catch {
      setPhoneError(t('Invalid phone number'));
      setIsPhoneEmpty(false);
    }
  };

  const isFormValid =
    formValues.first_name.trim() !== '' &&
    formValues.last_name.trim() !== '' &&
    formValues.contact_number.trim() !== '' &&
    formValues.role.trim() !== '' &&
    phoneError === '' &&
    !isPhoneEmpty &&
    formValues.email.trim() !== '' &&
    emailError === '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {isEditMode ? t('Edit User Details') : t('Add New User')}
        </DialogTitle>
        <Typography variant="subtitle1">
          {isEditMode
            ? t('Edit the below details in order to update the user.')
            : t(
                'Enter employee details to add them as Users on the GritCargo platform. Users will receive an email invite and be required to create their own secure password.'
              )}
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Role')}
          </Typography>
          <Select
            fullWidth
            displayEmpty
            value={formValues.role}
            sx={{ mb: 2 }}
            onChange={(e) => {
              setFormValues({ ...formValues, role: e.target.value });
            }}
            renderValue={(selected) => {
              if (selected === '') {
                return (
                  <span
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      textAlign: 'left',
                      color: '#C7C7C7',
                    }}
                  >
                    {t('Select Role')}
                  </span>
                );
              }
              return selected;
            }}
          >
            <MenuItem
              value="OPERATIONS"
              style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'left',
              }}
            >
              {t('OPERATIONS')}
            </MenuItem>
            <MenuItem
              value="ADMIN"
              style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'left',
              }}
            >
              {t('ADMIN')}
            </MenuItem>
          </Select>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('First Name')}*
          </Typography>
          <TextField
            placeholder={t('Enter First Name')}
            type="text"
            value={formValues.first_name}
            onChange={(e) =>
              setFormValues({ ...formValues, first_name: e.target.value })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Last Name')}*
          </Typography>
          <TextField
            placeholder={t('Enter Last Name')}
            type="text"
            value={formValues.last_name}
            onChange={(e) =>
              setFormValues({ ...formValues, last_name: e.target.value })
            }
            fullWidth
            sx={{ mb: 2, backgroundColor: 'white' }}
          />

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Contact Number')}*
          </Typography>
          <Box sx={{ mb: phoneError ? 0 : 2 }}>
            <StyledPhoneInput
              country={selectedCountry}
              countryCodeEditable={false}
              value={formValues.contact_number}
              onChange={handlePhoneChange}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: false,
                placeholder: '',
              }}
            />

            {phoneError && (
              <Typography
                variant="caption"
                color="#d32f2f"
                sx={{
                  margin: 0,
                  padding: 0,
                  display: 'block',
                  width: '100%',
                  textAlign: 'left',
                  marginBottom: 2,
                }}
              >
                {phoneError}
              </Typography>
            )}
          </Box>

          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('Email')}*
          </Typography>
          <TextField
            placeholder={t('Enter Email')}
            type="email"
            value={formValues.email}
            onChange={(e) => {
              setFormValues({ ...formValues, email: e.target.value });
              validateEmail(e.target.value);
            }}
            fullWidth
            error={emailError !== ''}
            helperText={emailError && emailError}
            sx={{
              'mb': 2,
              'backgroundColor': 'white',
              '& .MuiFormHelperText-root': {
                backgroundColor: '#FBFBFB',
                width: '100%',
                marginLeft: 0,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {isEditMode ? t('Edit User Profile') : t('Create User Profile')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default UserModal;

import React, { useEffect } from 'react';
import modalTheme from '../../../../theme/modalTheme';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface UnassignDriverModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const UnassignDriverModal: React.FC<UnassignDriverModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t('Unassign Driver')}</DialogTitle>
        <Typography variant="subtitle1">
          {t(
            'Unassiging a driver will remove them from their current vehicle. The driver will then be available to assign to another vehicle.'
          )}
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button variant="contained" onClick={onSubmit} color="primary">
            {t('Unassign Driver')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default UnassignDriverModal;

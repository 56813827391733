import {
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg';
import { ReactComponent as EllipsVertIcon } from '../../assets/icons/ellipsis-vertical.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/magnifying-glass.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/circle-xmark.svg';

import Pagination from '../Pagination/Pagination';
import NoDataState from './NoDataState';
import Calendar from '../Calendar/Calendar';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface HistoryTableComponentProps {
  title: string;
  searchValue: string;
  columns: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<{ [key: string]: any }>;
  columnFieldMapping: { [key: string]: string };
  columnWidths: string[];
  actions: (row: {
    [key: string]: unknown;
  }) => Array<{ label: string; icon: ReactNode; onClick: () => void }>;
  onSearchValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyDates: (from: Date | undefined, to: Date | undefined) => void;
}

const HistoryTableComponent: React.FC<HistoryTableComponentProps> = ({
  title,
  searchValue,
  columns,
  data,
  columnFieldMapping,
  columnWidths,
  actions,
  onSearchValueChange,
  onApplyDates,
}) => {
  const textFieldRef = useRef<HTMLInputElement>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | {
    [key: string]: unknown;
  }>(null);
  const [timePassed, setTimePassed] = useState<boolean>(false);
  const { t } = useTranslation();

  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<{
    from: Date | undefined;
    to?: Date | undefined;
  }>({ from: undefined, to: undefined });

  const handleIconClick = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    setTimeout(() => {
      setTimePassed(true);
    }, 1500);
  }, []);

  useEffect(() => {
    setLastUpdatedTime(getCurrentTime());
  }, [data]);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  } | null>(null);

  const handleSort = (column: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (
      sortConfig &&
      sortConfig.key === column &&
      sortConfig.direction === 'asc'
    ) {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const sortedData = useMemo(() => {
    if (sortConfig !== null) {
      return [...data].sort((a, b) => {
        const aValue = a[columnFieldMapping[sortConfig.key]];
        const bValue = b[columnFieldMapping[sortConfig.key]];

        if (aValue === null || aValue === '-')
          return sortConfig.direction === 'asc' ? 1 : -1;
        if (bValue === null || bValue === '-')
          return sortConfig.direction === 'asc' ? -1 : 1;

        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return data;
  }, [data, sortConfig, columnFieldMapping]);

  const calculateTotalPages = (totalEntries: number, rowsPerPage: number) => {
    return Math.ceil(totalEntries / rowsPerPage);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = useMemo(() => {
    return sortedData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );
  }, [sortedData, currentPage, rowsPerPage]);

  const handleActionClick = (
    event: React.MouseEvent<HTMLElement>,
    row: { [key: string]: unknown }
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const calculateRowsPerPage = () => {
    const rowHeight = 60;
    const headerHeight = 180;
    const footerHeight = 180;
    const availableHeight = window.innerHeight - headerHeight - footerHeight;

    return Math.floor(availableHeight / rowHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      const newRowsPerPage = calculateRowsPerPage();
      if (newRowsPerPage < 1) {
        setRowsPerPage(1);
      } else {
        setRowsPerPage(newRowsPerPage);
      }
      setCurrentPage(1);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTotalPages(calculateTotalPages(data.length, rowsPerPage));
  }, [rowsPerPage, data]);

  const handleApplyDates = (from: Date, to: Date) => {
    const endOfDay = new Date(to);
    endOfDay.setHours(23, 59, 59, 999);

    setSelectedDate({ from, to: endOfDay });
    onApplyDates(from, endOfDay);
  };

  return (
    <Grid2
      container
      display={'flex'}
      direction={'column'}
      spacing={2}
      sx={{
        height: '100%',
        minHeight: '250px',
        maxWidth: '100%',
      }}
    >
      <Grid2 container display={'flex'} alignItems={'baseline'}>
        <Typography variant="h4">{t(title)}</Typography>
        <Typography
          style={{
            fontSize: '12px',
            color: '#86BE02',
          }}
        >
          {t('Last updated at')} {lastUpdatedTime}
        </Typography>
      </Grid2>
      <Grid2
        display={'flex'}
        alignItems={'center'}
        sx={{
          height: '40px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: 'rgba(131, 131, 131, 0.4)',
          minWidth: '100%',
        }}
      >
        <CalendarIcon
          onClick={() => setOpenCalendar(true)}
          cursor={'pointer'}
          style={{
            width: '16px',
            height: '16px',
            marginLeft: '10px',
            padding: '8px 4px',
          }}
        />
        {selectedDate.from && selectedDate.to ? (
          <Grid2
            container
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              width: '340px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {format(selectedDate.from || new Date(), 'dd MMM ` yyyy') +
                '  -  ' +
                format(selectedDate.to || new Date(), 'dd MMM ` yyyy')}
            </Typography>
            <CloseIcon
              onClick={() => {
                setSelectedDate({ from: undefined, to: undefined });
                onApplyDates(undefined, undefined);
              }}
              cursor={'pointer'}
              style={{
                fill: 'black',
                width: '14px',
                height: '14px',
              }}
            />
          </Grid2>
        ) : null}

        <Grid2
          container
          display={'flex'}
          alignItems={'center'}
          sx={{
            height: '100%',
            width: '100%',
            borderLeft: '1px solid rgba(131, 131, 131, 0.4)',
            marginLeft: '10px',
          }}
        >
          <Grid2
            display={'flex'}
            alignItems={'center'}
            sx={{
              marginLeft: '10px',
            }}
          >
            <SearchIcon
              onClick={handleIconClick}
              cursor={'text'}
              style={{
                fill: '#838383',
                width: '15px',
                height: '15px',
                marginRight: '10px',
              }}
            />
          </Grid2>
          <Grid2
            sx={{
              width: '60%',
            }}
          >
            <TextField
              inputRef={textFieldRef}
              sx={{
                'width': '100%',
                'padding': '0px',
                '& .MuiOutlinedInput-root': {
                  'padding': '0px',
                  '& fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '0px',
                },
              }}
              value={searchValue}
              onChange={onSearchValueChange}
              placeholder={t('Search')}
              variant="outlined"
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2>
        {data.length === 0 && timePassed ? (
          <NoDataState />
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: 'none',
            }}
          >
            <Table sx={{ borderSpacing: '0 8px', borderCollapse: 'separate' }}>
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{
                        width: columnWidths[index],
                        cursor: 'pointer',
                        borderBottom: 'none',
                        color: 'rgba(131, 131, 131, 0.4)',
                      }}
                      onClick={() => handleSort(column)}
                    >
                      {t(column)}
                      {sortConfig && sortConfig.key === column ? (
                        sortConfig.direction === 'asc' ? (
                          <ArrowUpIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        ) : (
                          <ArrowDownIcon
                            style={{ width: '10px', marginLeft: '5px' }}
                          />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      backgroundColor:
                        rowIndex % 2 === 1 ? '#F5F5F5' : '#FBFBFB',
                    }}
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          width: columnWidths[colIndex],
                          borderBottom: 'none',
                          padding: '8px 16px',
                          whiteSpace: 'nowrap',
                        }}
                        sx={{
                          borderRadius:
                            colIndex === 0 ? '16px 0 0 16px' : 'none',
                        }}
                      >
                        {row[columnFieldMapping[column]] === null ||
                        row[columnFieldMapping[column]] === '' ? (
                          '-'
                        ) : row[columnFieldMapping[column]] === 'IN_USE' ||
                          row[columnFieldMapping[column]] === 'ACTIVE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Active')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'PAUSED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Paused')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'CANCELLED' ? (
                          <Typography color="#FF0000" fontSize={'0.875rem'}>
                            • {t('Cancelled')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'REGISTERED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Inactive')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'ASSIGNED_TO_VEHICLE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Assigned To Vehicle')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'UNASSIGNED_FROM_VEHICLE' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Unassigned From Vehicle')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'LEFT_UNLOCKED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Left Unlocked')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'RIGHT_UNLOCKED' ? (
                          <Typography color="#FF9500" fontSize={'0.875rem'}>
                            • {t('Right Unlocked')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] === 'LOCKED' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Locked')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'CRADLE_UNLOCKED' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Cradle Unlocked')}
                          </Typography>
                        ) : row[columnFieldMapping[column]] ===
                          'CRADLE_LOCKED' ? (
                          <Typography color="#34C759" fontSize={'0.875rem'}>
                            • {t('Cradle Locked')}
                          </Typography>
                        ) : (
                          row[columnFieldMapping[column]]
                        )}
                      </TableCell>
                    ))}
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: 'none',
                        borderRadius: '0 16px 16px 0',
                        padding: '8px',
                      }}
                    >
                      <IconButton
                        onClick={(event) => handleActionClick(event, row)}
                        disabled={
                          row[columnFieldMapping['Status']] === 'REGISTERED'
                        }
                      >
                        <EllipsVertIcon
                          style={{
                            fill:
                              row[columnFieldMapping['Status']] === 'REGISTERED'
                                ? 'rgba(131, 131, 131, 0.4)'
                                : 'black',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedRow === row}
                        onClose={handleClose}
                        sx={{
                          '& .MuiPaper-root': {
                            borderRadius: '20px',
                          },
                        }}
                      >
                        {actions(row).map((action, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              action.onClick();
                              handleClose();
                            }}
                            sx={{
                              padding: '12px 20px',
                            }}
                          >
                            {action.icon}
                            {t(action.label)}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid2>
      <Calendar
        open={openCalendar}
        setOpen={setOpenCalendar}
        onApplyDates={handleApplyDates}
      />
      {data.length === 0 ? null : (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </Grid2>
  );
};

export default HistoryTableComponent;

import {
  Box,
  Button,
  Grid2,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CompanyDriverCardsResponse } from '../../../../../interfaces/Driver/Response/companyDriverCards';
import { getCompanyDriverCards } from '../../../../../services/apiService';
import { CompanyDriverCardsRequest } from '../../../../../interfaces/Driver/Request/companyDriverCards';

import { ReactComponent as SignalIcon } from '../../../../../assets/icons/signal.svg';
import { ReactComponent as BluetoothIcon } from '../../../../../assets/icons/bluetooth.svg';
import { ReactComponent as BatteryIcon } from '../../../../../assets/icons/battery-half.svg';
import { ReactComponent as LockIcon } from '../../../../../assets/icons/lock.svg';
import { ReactComponent as UnlockIcon } from '../../../../../assets/icons/unlock.svg';
import { ReactComponent as SearchIcon } from '../../../../../assets/icons/magnifying-glass.svg';
import { ReactComponent as RouteIcon } from '../../../../../assets/icons/route.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/check.svg';
import { ReactComponent as CircleIcon } from '../../../../../assets/icons/circle.svg';
import { useNavigate } from 'react-router-dom';
import Locking from '../../../../../components/Locking/Locking';
import { setLocation } from '../../../../../utils/locationUtil';
import { getDriverId } from '../../../../../utils/expandDriverCard';
import { useTranslation } from 'react-i18next';

function DriverCards() {
  const navigate = useNavigate();
  const [cookies] = useCookies(['user']);
  const [drivers, setDrivers] = useState<CompanyDriverCardsResponse[]>([]);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [expandedCardId, setExpandedCardId] = useState('');
  const isSmallViewport = useMediaQuery('(max-width:1500px)');
  const { t } = useTranslation();

  const drawerWidth = isSmallViewport ? '350px' : '425px';
  const buttonFontSize = isSmallViewport ? '0.6rem' : '0.9rem';
  const iconWidth = isSmallViewport ? '12px' : '20px';
  const addressOverflow = isSmallViewport ? '170px' : '240px';
  const lockIconWidth = isSmallViewport ? '18px' : '24px';
  const summaryOverflow = isSmallViewport ? '130px' : '200px';
  const routeInfoSize = isSmallViewport ? '13px' : '14px';
  const addressSize = isSmallViewport ? '13px' : '14px';
  const addressMargin = isSmallViewport ? '2px' : '10px';

  const handleCardClick = (id: string, latitude: string, longitude: string) => {
    const lat = Number(latitude);
    const lng = Number(longitude);

    setExpandedCardId(expandedCardId === id ? '' : id);
    setLocation(lat, lng);
  };

  useEffect(() => {
    const fetchDrivers = async () => {
      const payload: CompanyDriverCardsRequest = {
        company_id: cookies.user.company_id,
        search: debouncedSearch,
      };
      try {
        const data = await getCompanyDriverCards(payload);
        setDrivers(data);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    };

    fetchDrivers();
  }, [debouncedSearch, cookies.user.company_id]);

  const hasProcessingState = (data: CompanyDriverCardsResponse[]) => {
    return data.some(
      (entry) =>
        entry.left_locked === 'PROCESSING' ||
        entry.right_locked === 'PROCESSING'
    );
  };

  const pollApi = async () => {
    const payload: CompanyDriverCardsRequest = {
      company_id: cookies.user.company_id,
      search: debouncedSearch,
    };
    try {
      const data = await getCompanyDriverCards(payload);
      setDrivers(data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (hasProcessingState(drivers)) {
      interval = setInterval(() => {
        pollApi();
      }, 4000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [drivers]);

  useEffect(() => {
    setTimeout(() => {
      setExpandedCardId(getDriverId());
    }, 500);
    const handleExpandCard = () => {
      setTimeout(() => {
        setExpandedCardId(getDriverId());
      }, 500);
    };
    window.addEventListener('openDriverCard', handleExpandCard);

    return () => {
      window.removeEventListener('openDriverCard', handleExpandCard);
    };
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearch(search);
    }, 200);

    return () => {
      clearTimeout(timerId);
    };
  }, [search]);

  const refetchInfo = async () => {
    const payload: CompanyDriverCardsRequest = {
      company_id: cookies.user.company_id,
      search: debouncedSearch,
    };
    try {
      const data = await getCompanyDriverCards(payload);
      setDrivers(data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const handleUnlockLeftClick = (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => {
    event.stopPropagation();
    setLeftLock(true);
    setCurrentLockState(lockState);
    setSelectedSeal(serial_number);
    setOpenLocking(true);
  };

  const handleUnlockRightClick = (
    event: React.MouseEvent,
    serial_number: string,
    lockState: boolean
  ) => {
    event.stopPropagation();
    setLeftLock(false);
    setCurrentLockState(lockState);
    setSelectedSeal(serial_number);
    setOpenLocking(true);
  };

  const handleEditRouteClick = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/vehicles/${id}`);
  };

  const [openLocking, setOpenLocking] = useState(false);
  const [leftLock, setLeftLock] = useState(false);
  const [currentLockState, setCurrentLockState] = useState(false);
  const [selectedSeal, setSelectedSeal] = useState('');

  return (
    <Box
      sx={{
        width: drawerWidth,
        minWidth: drawerWidth,
        height: '100vh',
      }}
    >
      <Grid2
        container
        spacing={2}
        direction={'column'}
        display={'flex'}
        margin={'0px 30px'}
      >
        <Grid2 marginTop={'10px'}>
          <TextField
            placeholder={t('Search')}
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            slotProps={{
              input: {
                startAdornment: (
                  <SearchIcon
                    style={{
                      height: '16px',
                      width: '16px',
                    }}
                  ></SearchIcon>
                ),
              },
            }}
            sx={{
              'backgroundColor': '#FFFFFF',
              'borderRadius': '8px',
              '& .MuiInputBase-input': {
                paddingLeft: '8px',
              },
              '& .MuiOutlinedInput-root': {
                'borderRadius': '8px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
              },
            }}
          />
        </Grid2>
        <Grid2>
          <Typography>{t('iMSeal Locks')}</Typography>
        </Grid2>
        <Grid2
          sx={{
            height: 'calc(100vh - 180px)',
            overflowY: 'auto',
            pointerEvents: 'auto',
          }}
        >
          {drivers.length !== 0 ? (
            <Grid2 container direction={'column'} display={'flex'} spacing={1}>
              {drivers.map((driver) => (
                <Grid2 key={driver.id}>
                  <Box
                    onClick={() =>
                      handleCardClick(
                        driver.id,
                        driver.latitude,
                        driver.longitude
                      )
                    }
                    sx={{
                      height: expandedCardId === driver.id ? '450px' : '88px',
                      transition: 'height 0.5s ease',
                      cursor: 'pointer',
                      backgroundColor: '#FFFFFF',
                      borderRadius: '16px',
                      border:
                        getDriverId() !== '' && driver.id === expandedCardId
                          ? '2px solid #DD3333'
                          : driver.id === expandedCardId
                          ? '2px solid #B4EE2B'
                          : undefined,

                      overflow: 'hidden',
                    }}
                  >
                    <Grid2
                      container
                      display={'flex'}
                      alignContent={'center'}
                      alignItems={'center'}
                      columnSpacing={1}
                      margin={'0px 16px'}
                      height={'100%'}
                      sx={{
                        transition: 'all 0.5s ease',
                      }}
                    >
                      <Grid2 container size={10}>
                        <Grid2
                          container
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          width={'100%'}
                          marginLeft={'10px'}
                        >
                          <Grid2>
                            <Typography>{driver.name}</Typography>
                          </Grid2>
                          <Grid2>
                            <Grid2 container display={'flex'} spacing={'8px'}>
                              <SignalIcon
                                style={{
                                  height: '12px',
                                  width: '12px',
                                }}
                              ></SignalIcon>
                              <BluetoothIcon
                                style={{
                                  height: '12px',
                                  width: '12px',
                                }}
                              ></BluetoothIcon>
                              <BatteryIcon
                                style={{
                                  height: '12px',
                                  width: '12px',
                                }}
                              ></BatteryIcon>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                        <Grid2
                          container
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'baseline'}
                          width={'100%'}
                          marginLeft={'10px'}
                        >
                          <Grid2>
                            <Typography
                              sx={{
                                color: '#232323',
                                fontSize: '0.8rem',
                              }}
                            >
                              {driver.vehicle_registration}
                            </Typography>
                          </Grid2>
                          <Grid2>
                            <Typography
                              sx={{
                                fontSize: '0.6rem',
                              }}
                            >
                              {driver.serial_number}
                            </Typography>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                      <Grid2
                        container
                        size={2}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Grid2
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: driver.lock_status
                              ? '#30D1581A'
                              : '#DD333333',
                            borderRadius: '12px',
                            padding: '10px',
                          }}
                        >
                          <LockIcon
                            style={{
                              width: lockIconWidth,
                              fill: driver.lock_status ? '#34C759' : '#BA1F1F',
                            }}
                          ></LockIcon>
                        </Grid2>
                      </Grid2>
                      {expandedCardId === driver.id && (
                        <Grid2
                          container
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          width={'100%'}
                          marginTop={'15px'}
                        >
                          <Grid2 size={6}>
                            <Button
                              fullWidth
                              onClick={(event) =>
                                handleUnlockLeftClick(
                                  event,
                                  driver.serial_number,
                                  driver.left_locked === 'LOCKED'
                                )
                              }
                              disabled={driver.left_locked === 'PROCESSING'}
                              sx={{
                                backgroundColor:
                                  driver.left_locked === 'LOCKED'
                                    ? '#000000'
                                    : driver.left_locked === 'UNLOCKED'
                                    ? '#B4EE2B'
                                    : '#C7C7C7',
                                borderRadius: '12px',
                                textTransform: 'none',
                                padding: '6px 20px',
                                height: '35px',
                              }}
                            >
                              <UnlockIcon
                                style={{
                                  fill:
                                    driver.left_locked === 'LOCKED'
                                      ? '#FFFFFF'
                                      : '#000000',
                                  height: iconWidth,
                                }}
                              ></UnlockIcon>
                              <Typography
                                sx={{
                                  color:
                                    driver.left_locked === 'LOCKED'
                                      ? '#FFFFFF'
                                      : '#000000',
                                }}
                                marginLeft={'6px'}
                                fontSize={buttonFontSize}
                              >
                                {driver.left_locked === 'LOCKED'
                                  ? t('Unlock Left')
                                  : driver.left_locked === 'UNLOCKED'
                                  ? t('Lock Left')
                                  : t('Processing...')}
                              </Typography>
                            </Button>
                          </Grid2>
                          <Grid2 size={6}>
                            <Button
                              fullWidth
                              onClick={(event) =>
                                handleUnlockRightClick(
                                  event,
                                  driver.serial_number,
                                  driver.right_locked === 'LOCKED'
                                )
                              }
                              disabled={driver.right_locked === 'PROCESSING'}
                              sx={{
                                backgroundColor:
                                  driver.right_locked === 'LOCKED'
                                    ? '#000000'
                                    : driver.right_locked === 'UNLOCKED'
                                    ? '#B4EE2B'
                                    : '#C7C7C7',
                                borderRadius: '12px',
                                textTransform: 'none',
                                padding: '6px 20px',
                                width: '100%',
                                height: '35px',
                              }}
                            >
                              <UnlockIcon
                                style={{
                                  fill:
                                    driver.right_locked === 'LOCKED'
                                      ? '#FFFFFF'
                                      : '#000000',
                                  height: iconWidth,
                                }}
                              ></UnlockIcon>
                              <Typography
                                sx={{
                                  color:
                                    driver.right_locked === 'LOCKED'
                                      ? '#FFFFFF'
                                      : '#000000',
                                }}
                                marginLeft={'6px'}
                                fontSize={buttonFontSize}
                              >
                                {driver.right_locked === 'LOCKED'
                                  ? t('Unlock Right')
                                  : driver.right_locked === 'UNLOCKED'
                                  ? t('Lock Right')
                                  : t('Processing...')}
                              </Typography>
                            </Button>
                          </Grid2>
                          <Grid2
                            container
                            display={'flex'}
                            alignItems={'center'}
                            width={'100%'}
                            spacing={1}
                            marginTop={'15px'}
                            sx={{
                              padding: '16px 16px',
                              backgroundColor: '#FBFBFB',
                              borderRadius: '16px',
                            }}
                          >
                            <Grid2 size={6}>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#838383',
                                }}
                              >
                                {t('Number Plate')}
                              </Typography>
                            </Grid2>
                            <Grid2
                              size={6}
                              display={'flex'}
                              flexDirection={'row-reverse'}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                }}
                              >
                                {driver.vehicle_registration}
                              </Typography>
                            </Grid2>
                            <Grid2 size={6}>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#838383',
                                }}
                              >
                                {t('Contact Number')}
                              </Typography>
                            </Grid2>
                            <Grid2
                              size={6}
                              display={'flex'}
                              flexDirection={'row-reverse'}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                }}
                              >
                                {driver.contact_number}
                              </Typography>
                            </Grid2>

                            <Grid2 size={6}>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: '#838383',
                                }}
                              >
                                {t('Vehicle Alias')}
                              </Typography>
                            </Grid2>
                            <Grid2
                              size={6}
                              display={'flex'}
                              flexDirection={'row-reverse'}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  maxWidth: summaryOverflow,
                                }}
                              >
                                {driver.vehicle_alias}
                              </Typography>
                            </Grid2>
                          </Grid2>
                          <Grid2
                            container
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            width={'100%'}
                            marginTop={'15px'}
                            sx={{
                              padding: '16px 16px',
                              backgroundColor: '#FBFBFB',
                              borderRadius: '16px',
                            }}
                          >
                            <Grid2 container>
                              <Grid2>
                                <RouteIcon
                                  style={{
                                    height: iconWidth,
                                  }}
                                />
                              </Grid2>
                              <Grid2>
                                <Typography
                                  sx={{
                                    fontSize: routeInfoSize,
                                  }}
                                >
                                  {t('Route Information')}
                                </Typography>
                              </Grid2>
                            </Grid2>
                            <Grid2>
                              <Button
                                fullWidth
                                onClick={(event) =>
                                  handleEditRouteClick(event, driver.vehicle_id)
                                }
                                sx={{
                                  backgroundColor: '#EEEEEE',
                                  borderRadius: '8px',
                                  textTransform: 'none',
                                  padding: '4px 8px',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: buttonFontSize,
                                    color: '#000000',
                                  }}
                                >
                                  {t('Edit Route')}
                                </Typography>
                              </Button>
                            </Grid2>

                            <Grid2
                              container
                              display={'flex'}
                              marginTop={'20px'}
                              marginLeft={addressMargin}
                              maxWidth={'100%'}
                            >
                              <Grid2 container display={'flex'} size={12}>
                                <Box
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    backgroundColor:
                                      driver.three_trip[0] === null
                                        ? '#E5E5E5'
                                        : '#B4EE2B',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {driver.three_trip[0] === null ? (
                                    <CircleIcon
                                      style={{
                                        fill: '#FFFFFF',
                                        height: '8px',
                                      }}
                                    />
                                  ) : (
                                    <CheckIcon
                                      style={{
                                        fill: '#FFFFFF',
                                        height: '16px',
                                      }}
                                    />
                                  )}
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: addressSize,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: addressOverflow,
                                  }}
                                >
                                  {driver.three_trip[0] === null
                                    ? 'N/A'
                                    : driver.three_trip[0]}
                                </Typography>
                              </Grid2>
                              <Grid2>
                                <Box
                                  sx={{
                                    width: '2px',
                                    height: '14px',
                                    backgroundColor:
                                      driver.three_trip[0] === null
                                        ? '#C7C7C7'
                                        : '#B4EE2B',
                                    margin: '4px 10px',
                                  }}
                                ></Box>
                              </Grid2>
                              <Grid2 container display={'flex'} size={12}>
                                <Box
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    backgroundColor:
                                      driver.three_trip[1] === null
                                        ? '#E5E5E5'
                                        : '#B4EE2B',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <CircleIcon
                                    style={{
                                      fill: '#FFFFFF',
                                      height: '8px',
                                    }}
                                  />
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: addressSize,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: addressOverflow,
                                  }}
                                >
                                  {driver.three_trip[1] === null
                                    ? 'N/A'
                                    : driver.three_trip[1]}
                                </Typography>
                              </Grid2>
                              <Grid2>
                                <Box
                                  sx={{
                                    width: '2px',
                                    height: '14px',
                                    backgroundColor: '#C7C7C7',
                                    margin: '4px 10px',
                                  }}
                                ></Box>
                              </Grid2>
                              <Grid2 container display={'flex'} size={12}>
                                <Box
                                  sx={{
                                    width: '24px',
                                    height: '24px',
                                    backgroundColor: '#E5E5E5',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <CircleIcon
                                    style={{
                                      fill: '#FFFFFF',
                                      height: '8px',
                                    }}
                                  />
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: addressSize,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: addressOverflow,
                                  }}
                                >
                                  {driver.three_trip[2] === null
                                    ? 'N/A'
                                    : driver.three_trip[2]}
                                </Typography>
                              </Grid2>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                      )}
                    </Grid2>
                  </Box>
                </Grid2>
              ))}
            </Grid2>
          ) : (
            <Typography
              sx={{
                fontSize: '20px',
              }}
            >
              {t('No iMSeals Found')}
            </Typography>
          )}
        </Grid2>
      </Grid2>
      <Locking
        open={openLocking}
        onClose={() => {
          setOpenLocking(false);
          refetchInfo();
        }}
        unlockLeft={leftLock}
        currentState={currentLockState}
        serial_number={selectedSeal}
        company_id={cookies.user.company_id}
      />
    </Box>
  );
}

export default DriverCards;

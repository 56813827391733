import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  InputAdornment,
  Autocomplete,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/magnifying-glass.svg';
import modalTheme from '../../../../theme/modalTheme';
import { GetUnusedSealsResponse } from '../../../../interfaces/Seals/Response/getUnusedSeals';
import { useTranslation } from 'react-i18next';

interface AssignVehicleToSealModalProps {
  open: boolean;
  onClose: () => void;
  vehicleDetails: {
    id?: string;
    vehicle_alias?: string;
    vehicle_registration?: string;
  };
  formValues: { seal_id: string; serial_number: string };
  setFormValues: React.Dispatch<
    React.SetStateAction<{ seal_id: string; serial_number: string }>
  >;
  seals: GetUnusedSealsResponse[];
  onSubmit: () => void;
}

const AssignVehicleToSealModal: React.FC<AssignVehicleToSealModalProps> = ({
  open,
  onClose,
  vehicleDetails,
  formValues,
  setFormValues,
  seals,
  onSubmit,
}) => {
  useEffect(() => {
    if (!open) {
      setFormValues({ seal_id: '', serial_number: '' });
    }
  }, [open, setFormValues]);
  const { t } = useTranslation();

  const isFormValid = formValues.seal_id.trim() !== '';

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormValid && open) {
        event.preventDefault();
        onSubmit();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFormValid, onSubmit, open]);

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{ '& .MuiDialog-paper': { width: '480px' } }}
      >
        <DialogTitle>
          {t('Assign iMSeal to')} {vehicleDetails.vehicle_alias}
        </DialogTitle>
        <Typography variant="subtitle1">{t('Search for a iMSeal')}</Typography>
        <DialogContent>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20px',
              textAlign: 'left',
            }}
          >
            {t('iMSeal')}
          </Typography>
          <Autocomplete
            options={seals}
            getOptionLabel={(option) => option.serial_number}
            value={seals.find((v) => v.id === formValues.seal_id) || null}
            onChange={(event, newValue) => {
              setFormValues({ seal_id: newValue?.id || '', serial_number: '' });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('Serial Number')}
                fullWidth
                sx={{ mb: 2, backgroundColor: 'white' }}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon
                          style={{
                            height: '14px',
                            width: '15px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={onSubmit}
            color="primary"
          >
            {t('Assign to Vehicle')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AssignVehicleToSealModal;

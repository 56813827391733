import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import modalTheme from '../../theme/modalTheme';
import SnackbarComponent from '../Snackbar/SnackbarComponent';
import { useTranslation } from 'react-i18next';
import { AddCommandRequest } from '../../interfaces/Seals/Request/addCommand';
import { addCommand } from '../../services/apiService';
import { useCookies } from 'react-cookie';

interface LockingProps {
  open: boolean;
  onClose: () => void;
  unlockLeft: boolean;
  currentState: boolean;
  serial_number: string;
  company_id: string;
}

const Locking: React.FC<LockingProps> = ({
  open,
  onClose,
  unlockLeft,
  currentState,
  serial_number,
  company_id,
}) => {
  const [cookies] = useCookies(['user']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarDuration, setSnackbarDuration] = useState(2000);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'success' | 'error' | 'warning' | 'info'
  >('error');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const { t } = useTranslation();

  const handleSubmitLock = async () => {
    const payload: AddCommandRequest = {
      company_id: company_id,
      seal_serial_number: serial_number,
      actioned_by:
        cookies.user.first_name +
        ' ' +
        cookies.user.last_name +
        ' - ' +
        cookies.user.role,
      command_code: unlockLeft
        ? currentState
          ? 'UL'
          : 'LK'
        : currentState
        ? 'UR'
        : 'LK',
    };

    const response = await addCommand(payload);

    if (response.statusCode === 400) {
      setSnackbarMessage(response.message);
      setSnackbarSeverity('error');
      setSnackbarDuration(2000);
      setSnackbarOpen(true);
    } else {
      setSnackbarMessage(
        t(response.data.message) +
          ' - ' +
          t('Expected time to complete in seconds') +
          ': ' +
          Math.round(response.data.expectedPingResponseTime)
      );
      setSnackbarSeverity('success');
      setSnackbarDuration(3000);
      setSnackbarOpen(true);
    }
    onClose();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && open) {
      event.preventDefault();
      handleSubmitLock();
    }
  };

  return (
    <ThemeProvider theme={modalTheme}>
      <Dialog open={open} onClose={onClose} onKeyDown={handleKeyDown}>
        {unlockLeft ? (
          <DialogTitle>
            {currentState ? t('Unlock Left') : t('Lock Left')}
          </DialogTitle>
        ) : (
          <DialogTitle>
            {currentState ? t('Unlock Right') : t('Lock Right')}
          </DialogTitle>
        )}
        <Typography variant="subtitle1">
          {t(
            'This action will attempt to lock/unlock the selected side of the lock, for'
          )}{' '}
          {serial_number}.{' '}
          {t(
            'Please confirm that this is the correct iMSeal and side to proceed.'
          )}
        </Typography>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitLock}
            color="primary"
          >
            {unlockLeft ? (
              <Typography>
                {currentState ? t('Unlock Left') : t('Lock Left')}
              </Typography>
            ) : (
              <Typography>
                {currentState ? t('Unlock Right') : t('Lock Right')}
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        message={snackbarMessage}
        severity={snackbarSeverity}
        open={snackbarOpen}
        duration={snackbarDuration}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
};

export default Locking;

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import shieldIcon from '../../../assets/icons/shield-halved.svg';
import eyeOpen from '../../../assets/icons/eye_on.svg';
import eyeClose from '../../../assets/icons/eye_off.svg';
import ShowPasswordStrength from './ShowPasswordStrength';
import { useLocation, useNavigate } from 'react-router-dom';
import { zxcvbn } from '@zxcvbn-ts/core';
import { resetPassword } from '../../../services/userService';
import { useTranslation } from 'react-i18next';
import { validatePasswordToken } from '../../../services/userService';

type Strength = 0 | 1 | 2 | 3 | 4;

interface CreateNewPasswordComponentProps {
  firstPassword: boolean;
}

const CreateNewPasswordComponent: React.FC<CreateNewPasswordComponentProps> = ({
  firstPassword,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [strength, setStrength] = useState<Strength>(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [email] = useState(location.state?.email || '');
  const [token] = useState(location.state?.token || '');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [validation, setValidation] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const [validationError, setValidationError] = useState<string | null>(null);
  const validationToken = queryParams.get('token');
  const [firstUseEmail, setFirstUseEmail] = useState('');
  //   const [firstUseOTP, setFirstUseOTP] = useState('');

  const calculateStrength = (password: string): Strength => {
    const result = zxcvbn(password);

    if (result.score === 0 && password.length >= 8) {
      return 1;
    }
    return result.score as Strength;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    setApiError(null);
    setStrength(calculateStrength(value));

    if (confirmPassword && value !== confirmPassword) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setApiError(null);

    if (value !== password) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (firstPassword) {
      handleValidation();
    }
  }, [firstPassword]);

  const handleValidation = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const response = await validatePasswordToken(validationToken || '');
      if (response.success) {
        setValidation(true);
        setFirstUseEmail(response.data.email);
        // setFirstUseOTP(response.data.otp);
      } else {
        setValidationError(
          t(
            'Request to change password is invalid or expired. Please contact support for further assistance.'
          )
        );
        setValidation(false);
      }
    } catch {
      setValidation(false);
      setValidationError(
        t(
          'Request to change password is invalid or expired. Please contact support for further assistance.'
        )
      );
    }
  };

  const handleCreateNewPassword = async () => {
    setLoading(true);
    setApiError(null);

    try {
      const response = await resetPassword(
        firstPassword ? firstUseEmail : email,
        password,
        firstPassword ? validationToken || '' : token,
        firstPassword ? validationToken || '' : ''
      );
      if (response.success) {
        navigate('/login', { replace: true });
      } else {
        setApiError(response.message);
      }
    } catch (error) {
      console.log(error);
      setApiError(
        'An error occurred while resetting the password. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isFormValid && !loading && validation) {
      event.preventDefault();
      handleCreateNewPassword();
    }
  };

  const isFormValid =
    password.length >= 8 &&
    password === confirmPassword &&
    password.trim() !== '' &&
    confirmPassword.trim() !== '';

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        minWidth: '30vw',
        maxWidth: '30vw',
        padding: '50.7px 70.98px 70.98px 70.98px',
        borderRadius: '16px',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'hidden',
        border: '2px solid #B4EE2B',
      }}
      onKeyDown={handleKeyDown}
    >
      {firstPassword && !validation ? (
        <>
          {validationError ? (
            <>
              <img
                src={shieldIcon}
                alt="Shield Icon"
                style={{
                  width: '75px',
                  height: '75px',
                  marginTop: '40.56px',
                }}
              />
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textAlign: 'center',
                  marginTop: '40px',
                }}
              >
                {validationError}
              </Typography>
            </>
          ) : (
            <>
              <CircularProgress sx={{ color: '#B4EE2B' }} size={100} />
              <Typography
                sx={{
                  fontFamily: 'Open Sans',
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  textAlign: 'center',
                  marginTop: '40px',
                }}
              >
                {t('Awaiting validation...')}
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          <img
            src={shieldIcon}
            alt="Laptop Not Found Icon"
            style={{
              width: '75px',
              height: '75px',
              marginTop: '40.56px',
            }}
          />

          <Typography
            sx={{
              fontFamily: 'Open Sans',
              fontSize: '32px',
              fontWeight: 600,
              lineHeight: '40px',
              textAlign: 'center',
              color: '#000000',
              marginTop: '45px',
            }}
          >
            {t('Create new Password')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginBottom: '25px',
              marginTop: '61px',
            }}
          >
            <TextField
              placeholder={t('Enter New Password')}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        <img
                          src={showPassword ? eyeOpen : eyeClose}
                          alt={
                            showPassword
                              ? t('Hide Password')
                              : t('Show Password')
                          }
                          style={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    color: '#000',
                    fontFamily: 'Open Sans',
                    fontSize: '16.22px',
                    fontWeight: 400,
                    lineHeight: '24.34px',
                    textAlign: 'left',
                  },
                },
              }}
              sx={{
                'backgroundColor': '#FFFFFF',
                'borderRadius': '8px',
                'marginBottom': '15px',
                '& .MuiOutlinedInput-root': {
                  'borderColor': '#C7C7C7',
                  '& fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C7C7C7',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '14px 14px',
                  color: '#000',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
                '& .MuiInputLabel-root': {
                  color: '#C7C7C7',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#C7C7C7',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#C7C7C7',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
              }}
            />

            <ShowPasswordStrength strength={strength} />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <TextField
              placeholder={t('Re-enter New Password')}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        <img
                          src={showConfirmPassword ? eyeOpen : eyeClose}
                          alt={
                            showConfirmPassword
                              ? t('Hide Password')
                              : t('Show Password')
                          }
                          style={{
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px',
                    color: '#000',
                    fontFamily: 'Open Sans',
                    fontSize: '16.22px',
                    fontWeight: 400,
                    lineHeight: '24.34px',
                    textAlign: 'left',
                  },
                },
              }}
              sx={{
                'backgroundColor': '#FFFFFF',
                'borderRadius': '8px',
                '& .MuiOutlinedInput-root': {
                  'borderColor': '#C7C7C7',
                  '& fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&:hover fieldset': {
                    borderColor: '#C7C7C7',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#C7C7C7',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: '14px 14px',
                  color: '#000',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
                '& .MuiInputLabel-root': {
                  color: '#C7C7C7',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#C7C7C7',
                },
                '& .MuiInputBase-input::placeholder': {
                  color: '#C7C7C7',
                  fontFamily: 'Open Sans',
                  fontSize: '16.22px',
                  fontWeight: 400,
                  lineHeight: '24.34px',
                  textAlign: 'left',
                },
              }}
            />
            <Typography
              sx={{
                color: errorMessage ? 'red' : 'transparent',
                fontSize: '14px',
                marginRight: 'auto',
              }}
            >
              {errorMessage ? t('Passwords do not match') : 'Placeholder'}
            </Typography>
          </Box>

          {apiError && (
            <Typography
              sx={{
                color: 'red',
                fontSize: '14px',
                marginBottom: '10px',
              }}
            >
              {apiError}
            </Typography>
          )}

          <Button
            sx={{
              marginTop: '52px',
              backgroundColor: isFormValid ? '#000000' : '#EEEEEE',
              color: isFormValid ? '#FFFFFF' : '#838383',
              padding: '16px 24px',
              borderRadius: '16px',
              width: '100%',
              fontSize: '18px',
              lineHeight: '28px',
              fontFamily: "'Open Sans', sans-serif",
              textAlign: 'center',
              textTransform: 'none',
              display: 'inline-block',
              position: 'relative',
              cursor: isFormValid ? 'pointer' : 'not-allowed',
            }}
            disabled={!isFormValid || loading}
            onClick={handleCreateNewPassword}
          >
            {t('Create Password')}
          </Button>
        </>
      )}
    </Box>
  );
};

export default CreateNewPasswordComponent;

let lat = -33.951692026436355;
let lng = 25.574639769654404;

export const getLocation = () => ({ lat, lng });

export const setLocation = (newLat: number, newLng: number) => {
  lat = newLat;
  lng = newLng;
  window.dispatchEvent(new Event('locationChange'));
};

import React from 'react';
import { Grid2 } from '@mui/material';
import SignUpForm from './Components/SignUpComponent';
import background from '../../assets/images/login_background.svg';

const LoginForm: React.FC = () => {
  return (
    <Grid2
      container
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      sx={{
        width: '100vw',
        height: '100vh',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Grid2 flexGrow={0.7}></Grid2>
      <Grid2>
        <SignUpForm />
      </Grid2>
    </Grid2>
  );
};

export default LoginForm;
